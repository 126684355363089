import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
	chips: { [key: string]: string[] };
};

const initialState: InitialStateType = {
	chips: {},
};

const prepare = (
	payload?: Record<string, any>,
	meta?: Record<string, any>,
	error?: Record<string, any>,
) => {
	return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};

export const filterChipsSlice = createSlice({
	name: 'filterChips',
	initialState,
	reducers: {
		GET_CHIP_FILTER_VALUES: {
			reducer: () => {},
			prepare,
		},
		SET_CHIP_FILTER_VALUES: {
			reducer: (state, { payload }) => {
				const selectedChips = { ...state.chips };
				state.chips = { ...selectedChips, ...payload.chips };
			},
			prepare,
		},
		RESET_CHIP_FILTER_VALUES: {
			reducer: (state, { payload }) => {
				const selectedChips = { ...state.chips };
				delete selectedChips[payload.chip];
				state.chips = selectedChips;
			},
			prepare,
		},
		RESET_CHIPS_FILTERS: {
			reducer: (state) => {
				state.chips = {};
			},
			prepare,
		},
	},
});

export const {
	GET_CHIP_FILTER_VALUES,
	SET_CHIP_FILTER_VALUES,
	RESET_CHIP_FILTER_VALUES,
	RESET_CHIPS_FILTERS,
} = filterChipsSlice.actions;

export default filterChipsSlice.reducer;
