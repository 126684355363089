import React, { ChangeEvent, Fragment, useEffect, useState, MouseEvent } from 'react';
import {
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	Divider,
	Fade,
	Grid,
	InputAdornment,
	InputLabel,
	Menu,
	Theme,
	Typography,
	Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { TreeView as MuiTreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Image from 'next/image';
import SearchIcon from '@/assets/icons/Search.svg';
import { theme } from '@/assets/theme';
import { Button } from './Button';
import { TextField } from './TextField';
import { bfsSearch, getNodeData } from '@/utils/helpers';
import {
	AnchorOriginType,
	TransformOriginType,
	TreeNode,
	TreeNodeDataType,
} from '@/utils/types';

const styles = {
	inputLabel: {
		fontSize: 13,
		fontWeight: 600,
		paddingBottom: 1,
		color: 'text.primary',
		lineHeight: '15.6px',
	},
	container: {
		minHeight: '600px',
		marginTop: '0.5rem',
		'& .MuiMenu-paper': {
			width: '320px',
			borderRadius: '8px',
		},
		'& .MuiMenu-list': {
			padding: 0,
		},
	},
	filterButton: {
		height: '46px',
		textAlign: 'right',
	},
	textField: {
		'& .MuiInputBase-root': {
			height: '46px',
			paddingX: '10px',
			input: {
				'&::placeholder': {
					color: (theme: Theme) => theme.palette.text.secondary,
					opacity: 1,
				},
				height: 'inherit',
				fontSize: '13px',
				lineHeight: '15.5px',
				padding: 0,
			},
			fieldset: {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
			'&.Mui-focused fieldset': {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
			'&:hover fieldset': {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
		},
	},
	divider: {
		color: (theme: Theme) => theme.borderColor.searchField,
	},
	list: {
		height: '220px',
		overflow: 'auto',
		padding: 0,
		paddingBottom: 2,
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '220px',
	},
	treeView: {
		height: '220px',
		overflow: 'auto',
		padding: 0,
		paddingBottom: 2,
	},
	treeItem: {
		'& .MuiTreeItem-label': {
			fontSize: '14px',
			lineHeight: '21px',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			maxWidth: 260,
		},
	},
	chipsBox: {
		padding: 1.3,
		border: '1px solid',
		borderRadius: '8px',
		borderColor: (theme: Theme) => theme.palette.text.primary,
		'&:hover': {
			cursor: 'pointer',
			borderColor: 'primary.main',
		},
	},
	chip: { mr: 1, my: 1 },
	loader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '240px',
	},
	selectLabel: {
		fontSize: 13,
		fontWeight: 600,
		paddingBottom: 1,
		color: 'text.primary',
		lineHeight: '15.6px',
	},
	selectPlaceholder: {
		color: (theme: Theme) => theme.palette.text.secondary,
		opacity: 1,
		fontSize: 13,
	},
	errorLabel: {
		fontSize: 12,
		marginTop: 1,
		fontWeight: 600,
		paddingBottom: 1,
		lineHeight: '15.6px',
		color: (theme: Theme) => theme.palette.error.main,
	},
};

type TreeViewPropsType = {
	chip: string;
	open: boolean;
	data: TreeNode[];
	loading?: boolean;
	selectBox?: boolean;
	searchField: boolean;
	isNodeWithObject?: boolean;
	selectedNodes: string[];
	selectedNodesWithObject?: TreeNodeDataType[];
	width?: string | number;
	anchorOrigin?: AnchorOriginType;
	transformOrigin?: TransformOriginType;
	anchorEl: HTMLInputElement | null;
	resetText?: string;
	handleClose: () => void;
	handleReset: (chip: string) => void;
	handleChange: (
		event: ChangeEvent<HTMLInputElement>,
		selectedNodes: string[] | TreeNodeDataType[],
		chip?: string,
	) => void;
	removeSelectedTreeValue?: (id: string) => void;
	placeholderText?: string;
	errorText?: string;
	tooltipText?: string;
	inputLabel?: string;
	required?: boolean;
	handleClickTreeView?: (event: MouseEvent<HTMLElement>) => void;
};

export const TreeView = ({
	chip,
	open,
	loading,
	data = [],
	anchorEl,
	selectBox = false,
	searchField,
	isNodeWithObject,
	handleClose,
	handleReset,
	handleChange,
	removeSelectedTreeValue = () => {},
	selectedNodes = [],
	selectedNodesWithObject = [],
	width = 320,
	anchorOrigin = {
		vertical: 'top',
		horizontal: 'left',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'left',
	},
	resetText = 'Reset Filter',
	placeholderText = '',
	errorText = '',
	tooltipText = '',
	inputLabel = '',
	required = false,
	handleClickTreeView,
}: TreeViewPropsType) => {
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [menuAnchor, setMenuAnchor] = useState<HTMLInputElement | null>(null);
	const [dataTree, setDataTree] = useState<TreeNode[]>([]);
	const [expanded, setExpanded] = useState<string[]>([]);

	useEffect(() => {
		if (!loading || open) {
			setDataTree(data);
		}
	}, [data, loading, open]);

	useEffect(() => {
		setOpenMenu(open);
		setMenuAnchor(anchorEl);
	}, [anchorEl, open]);

	const handleNodeSelect = (
		event: any,
		node: Record<string, any>,
		indeterminate: boolean,
	) => {
		event.stopPropagation();
		const nodeId = node.id;
		const allChildren = getAllChildren(nodeId);
		let newSelectedNodes = [...selectedNodes];
		let newSelectedNodesWithObject: TreeNodeDataType[] = [...selectedNodesWithObject];
		if (
			newSelectedNodes.includes(nodeId) &&
			!(event.target as HTMLInputElement).checked &&
			!indeterminate
		) {
			newSelectedNodes = newSelectedNodes.filter((id) => !allChildren.includes(id));
			if (isNodeWithObject) {
				newSelectedNodesWithObject = newSelectedNodesWithObject.filter(
					(obj) => obj.id && !allChildren.includes(obj.id),
				);
			}
		} else if (newSelectedNodes.includes(nodeId) && allChildren.length > 1) {
			allChildren.map((child) => {
				if (!newSelectedNodes.includes(child)) {
					newSelectedNodes.push(child);
					if (isNodeWithObject) {
						const nodeData = getNodeData(data, child);
						nodeData && newSelectedNodesWithObject.push(nodeData);
					}
				}
			});
		} else {
			newSelectedNodes.push(nodeId);
		}
		if (isNodeWithObject && !selectedNodesWithObject.some((obj) => obj.id === nodeId)) {
			newSelectedNodesWithObject.push({ id: nodeId, name: node.name, level: node.level });
		}

		handleChange(event, isNodeWithObject ? newSelectedNodesWithObject : newSelectedNodes);
	};

	// Retrieve all IDs from a node to its children
	function getAllIds(node: TreeNode, idList: string[] = []) {
		idList.push(node.id);
		if (node.children) {
			node.children.forEach((child) => getAllIds(child, idList));
		}
		return idList;
	}

	// Get IDs of all children from a specific node
	const getAllChildren = (id: string) => {
		const node = bfsSearch(data as TreeNode[], id);
		if (node) {
			return getAllIds(node);
		}
		return [];
	};

	const renderTree = (node: TreeNode) => {
		const isExpanded = expanded && expanded.includes(node.id);
		const isParentSelected = selectedNodes.includes(node.id);

		const checkAllChildrenSelected = (node: Record<string, any>) => {
			if (node.children?.length > 0) {
				return node.children.every((child: Record<string, any>) =>
					child.children?.length > 0
						? checkAllChildrenSelected(child)
						: selectedNodes.includes(child.id),
				);
			} else return selectedNodes.includes(node.id);
		};

		const isAllChildrenSelected = checkAllChildrenSelected(node) ?? true;
		const isIndeterminate = isParentSelected && !isAllChildrenSelected;

		return (
			<TreeItem
				sx={styles.treeItem}
				key={node.id}
				nodeId={node.id}
				label={
					<>
						<Checkbox
							id={node.id}
							indeterminate={isIndeterminate}
							checked={selectedNodes.includes(node.id)}
							tabIndex={-1}
							disableRipple
							onChange={(event) => handleNodeSelect(event, node, isIndeterminate)}
						/>
						{node.name}
					</>
				}
				icon={
					Array.isArray(node.children) ? (
						isExpanded ? (
							<ExpandMoreIcon onClick={(event) => handleToggleExpand(event, node.id)} />
						) : (
							<ChevronRightIcon onClick={(event) => handleToggleExpand(event, node.id)} />
						)
					) : null
				}
			>
				{Array.isArray(node.children)
					? node.children.map((node) => renderTree(node))
					: null}
			</TreeItem>
		);
	};

	const handleToggleExpand = (event: any, nodeId: string) => {
		event.stopPropagation();
		setExpanded((prevExpanded) =>
			prevExpanded.includes(nodeId)
				? prevExpanded.filter((id) => id !== nodeId)
				: [...prevExpanded, nodeId],
		);
	};

	const handleSearchOptions = (event: any) => {
		const value = event.target.value;
		if (value.length > 1) {
			const matchedNodes = searchInTree(
				dataTree.length ? dataTree : data,
				value,
				expanded,
				selectedNodes,
			);
			setDataTree(matchedNodes as TreeNode[]);
		} else {
			setDataTree(data);
			setExpanded([]);
		}
	};

	const isNodeNotPresent = (result: Record<string, any>, nodeId: string): boolean => {
		return !result.some((n: Record<string, any>) => n.id === nodeId);
	};

	const searchInTree = (
		tree: TreeNode[],
		search: string,
		expandedNodes: string[],
		selectedNodes: string[],
	) => {
		const searchWords = search.toLowerCase().split(/\s+/);
		return tree.reduce((result: Record<string, any>, node: TreeNode) => {
			const nodeText = node.name.toLowerCase();
			const found = searchWords.some((word) => nodeText.includes(word));
			const isSelectedNode =
				Array.isArray(selectedNodes) && selectedNodes.includes(node.id);
			if ((found || isSelectedNode) && isNodeNotPresent(result, node.id)) {
				result.push({ ...node, expanded: true });
			}
			if (Array.isArray(node.children)) {
				const matchedChildren = searchInTree(
					node.children,
					search,
					expandedNodes,
					selectedNodes,
				);
				if (matchedChildren.length > 0 && isNodeNotPresent(result, node.id)) {
					result.push({
						...node,
						expanded: true,
						children: matchedChildren,
					});
					// Mark node as expanded if it has matched children
					expandedNodes.push(node.id);
				}
			}
			return result;
		}, []);
	};

	const handleOpenTreeView = (event: React.MouseEvent<HTMLElement>) => {
		setOpenMenu(true);
		setMenuAnchor(event.currentTarget as any);
	};

	const handleCloseTreeView = () => {
		setOpenMenu(false);
		setMenuAnchor(null);
		handleClose();
	};

	return (
		<>
			{selectBox && (
				<Box onClick={handleClickTreeView}>
					<Grid container alignItems="top" gap={1}>
						<InputLabel sx={styles.inputLabel}>{`${inputLabel} ${
							required ? ' *' : ''
						}`}</InputLabel>
						{tooltipText && (
							<Tooltip
								title={
									<Typography fontSize={12} sx={{ textTransform: 'none' }}>
										{tooltipText}
									</Typography>
								}
								placement="bottom-start"
							>
								<InfoIcon sx={{ height: '14px', width: '14px' }} />
							</Tooltip>
						)}
					</Grid>
					<Box
						sx={{
							...styles.chipsBox,
							padding: selectedNodes.length ? 0.8 : 1.3,
							borderColor:
								!selectedNodes || errorText
									? theme.palette.error.main
									: styles.chipsBox.borderColor,
						}}
						onClick={handleOpenTreeView}
					>
						{selectedNodes.length ? (
							selectedNodes.map((nodeId, index) => (
								<Chip
									key={nodeId}
									label={getNodeData(data, nodeId)!.name}
									variant="outlined"
									sx={styles.chip}
									onDelete={() => removeSelectedTreeValue(nodeId)}
								/>
							))
						) : (
							<Typography sx={styles.selectPlaceholder}>{placeholderText}</Typography>
						)}
					</Box>
					{errorText && <InputLabel sx={styles.errorLabel}>{errorText}</InputLabel>}
				</Box>
			)}
			<Menu
				sx={styles.container}
				anchorEl={menuAnchor}
				open={openMenu}
				onClose={handleCloseTreeView}
				TransitionComponent={Fade}
				anchorOrigin={anchorOrigin}
				transformOrigin={transformOrigin}
				PaperProps={{
					style: { width },
				}}
			>
				{searchField && (
					<TextField
						propagation
						placeholder="Search..."
						variant="standard"
						type="search"
						customStyle={styles.textField}
						onChange={handleSearchOptions}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Image src={SearchIcon} height={15} width={15} alt="search-icon" />
								</InputAdornment>
							),
							disableUnderline: true,
						}}
					/>
				)}

				<Divider sx={styles.divider} />
				<Grid
					container
					sx={styles.filterButton}
					alignItems="center"
					justifyContent="flex-end"
				>
					<Grid item xs={12}>
						<Button
							text={resetText}
							variant="text"
							onClick={() => {
								handleReset(chip);
							}}
							customStyles={{ height: '16px' }}
						/>
					</Grid>
				</Grid>
				<Divider sx={styles.divider} />

				{loading ? (
					<Grid sx={styles.box}>
						<CircularProgress />
					</Grid>
				) : !loading && dataTree.length < 1 ? (
					<Grid sx={styles.box}>
						<Typography textAlign={'center'} alignContent={'center'} alignSelf={'center'}>
							{'No options found'}
						</Typography>
					</Grid>
				) : (
					<MuiTreeView
						multiSelect
						expanded={expanded}
						sx={styles.treeView}
						selected={selectedNodes}
						defaultExpandIcon={<ChevronRightIcon />}
						defaultCollapseIcon={<ExpandMoreIcon />}
					>
						{dataTree.map((node) => renderTree(node))}
					</MuiTreeView>
				)}
			</Menu>
		</>
	);
};
