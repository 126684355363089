import React, { FC, useState } from 'react';
import { slice } from 'lodash';
import { Avatar, Badge, AvatarGroup as MuiAvatarGroup, Tooltip } from '@mui/material';
import { optimizeImg } from '@/utils/helpers';

type AvatarGroupProps = {
	customStyle?: Record<string, any>;
	data: Record<string, any>[];
	maxItems?: number;
	withBadge?: boolean;
};

const styles = {
	avatarContainer: {
		width: '100%',
		justifyContent: 'start',
		'& .MuiAvatar-root': {
			height: '28px',
			width: '28px',
			fontSize: '12px',
		},
	},
	badge: {
		'& .MuiBadge-badge': {
			backgroundColor: '#44b700',
			boxShadow: '0 0 0 1px #FFF',
		},
	},
};

const avatarWithTooltip = (item: Record<string, any>) => (
	<Tooltip title={item.userName || item.name} key={item.id}>
		<Avatar alt={item.name} src={optimizeImg(item.pictureUrl, 32)} />
	</Tooltip>
);

export const AvatarGroup: FC<AvatarGroupProps> = ({
	data,
	maxItems = 4,
	customStyle = {},
	withBadge = false,
}) => {
	const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

	const showRemainingUsers = (data: Record<string, any>[], maxItems: number) => {
		return slice(data, maxItems - 1, data.length)
			.map((item) => item.userName)
			.join('\n');
	};

	return (
		<Tooltip
			open={tooltipOpen}
			title={showRemainingUsers(data, maxItems)}
			componentsProps={{ tooltip: { sx: { whiteSpace: 'pre-line' } } }}
		>
			<MuiAvatarGroup
				max={maxItems}
				sx={{ ...styles.avatarContainer, ...customStyle }}
				slotProps={{
					additionalAvatar: {
						onMouseEnter: () => setTooltipOpen(true),
						onMouseLeave: () => setTooltipOpen(false),
					},
				}}
			>
				{data.length > 0 &&
					data.map((item) =>
						withBadge ? (
							<Badge
								overlap="circular"
								anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
								variant="dot"
								key={item.id}
								sx={styles.badge}
							>
								{avatarWithTooltip(item)}
							</Badge>
						) : (
							avatarWithTooltip(item)
						),
					)}
			</MuiAvatarGroup>
		</Tooltip>
	);
};
