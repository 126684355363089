import { init } from 'filestack-js';
import { FILESTACK_KEY } from '@/utils/constants';

const client = init(FILESTACK_KEY);

type UploadFileOptions = {
	file: File;
	onSuccess: (url: string) => void;
	onError: (error: Error) => void;
	progress: (percentage: number) => void;
	maxSize?: number;
};

type UploadBlobOptions = {
	blob: Blob;
	onSuccess: (url: string) => void;
};

export const uploadFileToFilestack = async ({
	file,
	onSuccess,
	onError,
	progress,
	maxSize,
}: UploadFileOptions) => {
	const onProgress = (evt: any) => {
		progress(evt.totalPercent);
	};
	try {
		if (maxSize && file.size > maxSize) {
			onError(new Error('File size exceeds maximum of 20mb'));
			return;
		}
		await client
			.upload(file, {
				onProgress,
				partSize: maxSize,
			})
			.then((res) => {
				onSuccess(res.url);
			});
	} catch (error: any) {
		onError(error);
	}
};

export const uploadBlobToFilestack = async ({ blob, onSuccess }: UploadBlobOptions) => {
	const { url } = await client.upload(blob);
	onSuccess(url);
};
