/* eslint-disable no-unused-vars */
export enum USER_STATUS {
	NOT_INVITED = 'not_invited',
	INVITED = 'invited',
	ACCEPTED = 'accepted',
	INACTIVE = 'inactive',
}

export enum TASK_STATUS {
	TODO = 'to_do',
	IN_PROGRESS = 'in_progress',
	DONE = 'done',
}

export enum TASK_TYPE {
	SURVEY = 'survey',
	DATA = 'data',
	DELIVERABLE = 'deliverable',
	GENERAL = 'general',
}

export enum TASK_PRIORITY {
	LOW = 'low',
	HIGH = 'high',
	MEDIUM = 'medium',
}

export enum WORKFLOW_STATUS {
	SUBMISSION_PENDING = 'submission_pending',
	WORKING = 'working',
	NEEDS_ATTENTION = 'needs_attention',
	REQUIRES_RESUBMISSION = 'requires_resubmission',
	IN_REVIEW = 'in_review',
	AWAITING_FEEDBACK = 'awaiting_feedback',
	WAITING_ON = 'waiting_on',
	DONE = 'done',
}

export enum TASK_DUE_OPTION {
	OVERDUE = 'overdue',
	DUE_NEXT_WEEK = 'due_next_week',
}

export enum NOTIFICATION_TYPE {
	START_TASK_REMINDER = 'start_task_reminder',
	DUE_TASK_REMINDER = 'due_task_reminder',
	OVERDUE_TASK_REMINDER = 'overdue_task_reminder',
	OVERDUE_TASK_FOLLOWUP_REMINDER = 'overdue_task_followup_reminder',
	TASK_SUBMITTED = 'task_submitted',
	TASK_MARKED_AS_IN_REVIEW = 'task_marked_as_in_review',
	TASK_MOVED_TO_WORKING_FROM_IN_REVIEW = 'task_moved_to_working_from_in_review',
	TASK_MARKED_AS_NEEDS_ATTENTION = 'task_marked_as_needs_attention',
	TASK_MARKED_AS_REQUIRES_RESUBMISSION = 'task_marked_as_requires_resubmission',
	TASK_MOVED_TO_FEEDBACK_SHARED_WITH_CONTACT_FROM_NEEDS_ATTENTION = 'task_moved_to_feedback_shared_with_contact_from_needs_attention',
	USER_FEEDBACK_ON_TASK = 'user_feedback_on_task',
	EXTERNAL_USER_COMMENT_ON_TASK = 'external_user_comment_on_task',
	USER_MENTIONED_IN_TASK_NOTES = 'user_mentioned_in_task_notes',
	USER_MENTIONED_IN_TASK_SUMMARY = 'user_mentioned_in_task_summary',
	USER_MENTIONED_IN_TASK_COMMENT = 'user_mentioned_in_task_comment',
	USER_COMMENTED_ON_TASK = 'user_commented_on_task',
	USER_MENTIONED_IN_DASHBOARD_SUMMARY = 'user_mentioned_in_dashboard_summary',
	USER_MENTIONED_IN_DASHBOARD_COMMENT = 'user_mentioned_in_dashboard_comment',
	TASK_ALL_DATA_RECEIVED = 'task_all_data_received',
	DASHBOARD_SHARED_WITH_USER = 'dashboard_shared_with_user',
	NEW_ORGANIZATION_CREATED = 'new_organization_created',
	NEW_USER_ADDED_TO_TASK = 'new_user_added_to_task',
	NEW_TASK_ADDED = 'new_task_added',
	NEW_FILE_ADDED = 'new_file_added',
	NEW_FILE_ADDED_THROUGH_SECURE_LINK = 'new_file_added_through_secure_link',
	TASK_SURVEY_REOPENED = 'task_survey_reopened',
	TASK_WORKFLOW_NOT_UPDATED_FOR_7_DAYS = 'task_workflow_not_updated_for_7_days',
	TASK_WORKFLOW_NOT_UPDATED_FOR_10_DAYS = 'task_workflow_not_updated_for_10_days',
}

export enum SURVEY_TYPE {
	SURVEY = 'survey',
	DATA = 'data',
	EXTERNAL_SURVEY = 'external_survey',
}
