import Image from 'next/image';
import { Box, Grid, Menu as MuiMenu, Theme, Typography } from '@mui/material';
import HelpIcon from '@/assets/icons/HelpCircleIcon.svg';
import { Loader } from '@/components';
import { HelpDocType } from '@/utils/types';

const styles = {
	container: {
		p: 3,
		background: '#f9f9fb',
	},
	paper: {
		width: '400px',
		mt: 2,
		borderRadius: '0px',
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		p: 0,
		'& ul, & li': {
			p: 0,
		},
		'&: hover': {
			backgroundColor: (theme: Theme) => theme.palette.common.white,
		},
	},
	menuItem: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		p: 2,
		cursor: 'pointer',
	},
	header: {
		px: 3,
		pt: 3,
		background: (theme: Theme) => theme.palette.common.white,
	},
	heading: {
		fontSize: '18px',
		color: '#121212',
	},
	loadingContainer: {
		height: '400px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

type HelpMenuProps = {
	anchorEl: HTMLElement | null;
	handleClose: () => void;
	isLoading: boolean;
	helpDocs: HelpDocType[];
};

export const HelpMenu = ({
	anchorEl,
	handleClose,
	isLoading,
	helpDocs,
}: HelpMenuProps) => {
	const onItemClick = (helpDocId: string) => {
		window.open(`help-docs/${helpDocId}`, '_blank');
	};

	return (
		<MuiMenu
			sx={{ height: '800px' }}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			PaperProps={{ sx: styles.paper }}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Grid container gap={2} onMouseLeave={handleClose}>
				<Grid item sx={styles.header} xs={12}>
					<Grid container gap={1.25} alignItems="center">
						<Grid item mt={0.5}>
							<Image src={HelpIcon} alt="Help Icon" width={18.67} height={18.67} />
						</Grid>
						<Grid item>
							<Typography variant="body1" sx={styles.heading}>
								Help Docs
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container direction="column" xs={12} gap={1} sx={styles.container}>
					{isLoading ? (
						<Box sx={styles.loadingContainer}>
							<Loader backdrop={false} />
						</Box>
					) : (
						helpDocs.map((helpDoc: HelpDocType) => (
							<Box
								sx={styles.menuItem}
								onClick={() => onItemClick(helpDoc.id)}
								key={helpDoc.id}
							>
								<Typography
									variant="body1"
									sx={{
										fontSize: '13px',
										color: (theme: Theme) => theme.palette.text.primary,
									}}
								>
									{helpDoc.title}
								</Typography>
							</Box>
						))
					)}
				</Grid>
			</Grid>
		</MuiMenu>
	);
};
