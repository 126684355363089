import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Divider, Grid, Typography, Theme } from '@mui/material';
import { CopyButton } from './CopyButton';
import { Filter } from './Filter';
import { Chip } from './Chip';
import { TreeView } from './TreeView';
import filterIcon from '@/assets/icons/filter.svg';
import useMixpanel from '@/hooks/useMixpanel';
import {
	RESET_CHIPS_FILTERS,
	RESET_CHIP_FILTER_VALUES,
	SET_CHIP_FILTER_VALUES,
} from '@/store/actions';
import { RootState } from '@/store/slices';
import { camelToSnakeCase, getPageTitle, replaceUrlQuery } from '@/utils/helpers';
import { FilterOptionsType, TreeNodeDataType } from '@/utils/types';

type FilterChipsPropsType = {
	loading?: boolean;
	selectedChip: string;
	chips: FilterOptionsType[];
	options: Record<string, any>;
	selectedValues: Record<string, any>;
	handleReset: (chip: string) => void;
	handleSearch?: (_arg: string) => void;
	handleSelectedChip: (chip: string) => void;
	handleChange: (selectedNodes: { [key: string]: string[] }) => void;
	showCopyButton?: boolean;
};

const styles = {
	filterHeader: {
		px: 3,
		bgcolor: '#fff',
		height: '58px',
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
	},
	chip: {
		color: (theme: Theme) => theme.palette.text.secondary,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderColor: (theme: Theme) => theme.palette.text.secondary,
		height: '26px',
		'& .MuiChip-label': {
			padding: '5px 20px ',
			fontSize: '13px',
			fontWeight: 600,
			lineHeight: '15.6px',
		},
	},
	selectedOptionsChip: {
		color: '#FFF',
		backgroundColor: (theme: Theme) => theme.palette.primary.dark,
		borderColor: (theme: Theme) => theme.palette.text.secondary,
		height: '26px',
		'& .MuiChip-label': {
			padding: '5px 20px ',
			fontSize: '13px',
			fontWeight: 600,
			lineHeight: '15.6px',
		},
		'&&:hover': {
			color: (theme: Theme) => theme.palette.text.secondary,
			backgroundColor: (theme: Theme) => theme.palette.common.white,
		},
	},
};

export const FilterChips = ({
	chips = [],
	options,
	handleReset,
	selectedChip,
	handleChange,
	handleSearch,
	selectedValues,
	handleSelectedChip,
	loading,
	showCopyButton = true,
}: FilterChipsPropsType) => {
	const dispatch = useDispatch();
	const { track } = useMixpanel();
	const router = useRouter();
	const { currentView } = useSelector((state: RootState) => state.layout);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null);

	const getSelectedChip = () => chips.find((c) => c.id === selectedChip);

	const handleClick = (event: React.SyntheticEvent<Element, Event>, chip: string) => {
		setIsOpen(true);
		setAnchorEl(event.currentTarget as HTMLInputElement);
		handleSelectedChip(chip);
	};

	const handleClose = () => {
		setIsOpen(false);
		setAnchorEl(null);
	};

	const hasSelectedOptions = (chipId: string) => {
		const selectedOptions = selectedValues[chipId];
		return selectedOptions && selectedOptions.length > 0;
	};

	const trackSelectedFilters = (selectedChipValues: Record<string, any>): void => {
		const selectedFilters = Object.entries(selectedChipValues).reduce(
			(acc, [chipKey, chipValues]) => {
				if (!chipValues.length) return acc;
				const selectedOptions =
					chipKey === 'organization'
						? chipValues
						: options[chipKey].filter((option: Record<string, any>) =>
								chipValues.includes(option.id),
						  );

				const selectedData = selectedOptions
					.map((option: Record<string, any>) => option.name || option.userName)
					.join(', ');

				acc[camelToSnakeCase(chipKey)] = selectedData;
				return acc;
			},
			{} as Record<string, string>,
		);

		track('Filter Used', { ...selectedFilters, filter_page: getPageTitle() });
	};

	const handleFilterChange = (event: ChangeEvent<HTMLInputElement>, chip: string) => {
		const { id, checked } = event.target;
		const selectedChipValues = {
			...selectedValues,
			[chip]: checked
				? [...(selectedValues[chip] || []), id]
				: selectedValues[chip].filter((value: string) => value !== id),
		};
		changeFilterValues(selectedChipValues);
	};

	const handleTreeViewFilterChange = (
		_event: ChangeEvent<HTMLInputElement>,
		selectedNodes: string[] | TreeNodeDataType[],
	) => {
		const selectedChipValues = {
			...selectedValues,
			organization: selectedNodes,
		};
		changeFilterValues(selectedChipValues);
	};

	const changeFilterValues = (values: Record<string, any>) => {
		if (router.pathname.startsWith('/tasks') && currentView !== 'custom-view') {
			dispatch(RESET_CHIPS_FILTERS());
		}
		const persistValues = Object.keys(values).reduce((acc: any, key) => {
			if (!chips.find((c) => c.id === key)?.dontPersist) {
				acc[key] = values[key];
			}
			return acc;
		}, {});
		replaceUrlQuery(router, persistValues, currentView);
		dispatch(SET_CHIP_FILTER_VALUES({ chips: persistValues }));
		handleChange(values as { [key: string]: string[] });
		trackSelectedFilters(values);
	};

	const resetFilter = (chip: string) => {
		track('Filter Reset', {
			filter_name: chips.find((chipObj) => chipObj.id === chip)?.name,
			filter_page: getPageTitle(),
		});
		replaceUrlQuery(router, { [chip]: [] }, currentView);
		dispatch(RESET_CHIP_FILTER_VALUES({ chip }));
		handleReset(chip);
	};

	return (
		<Grid container alignItems="center" sx={styles.filterHeader} flexWrap="nowrap">
			<Grid item>
				<Grid container gap={'10px'} alignItems="center" flexWrap="nowrap">
					<Image src={filterIcon} alt="filter-icon" height={16} width={16} />
					<Typography
						color="textPrimary"
						fontSize="14px"
						fontWeight="600"
						lineHeight="16.8px"
					>
						Filters
					</Typography>
				</Grid>
			</Grid>
			<Divider
				orientation="vertical"
				flexItem
				sx={{ paddingX: '8px', marginY: '12px', borderColor: '#F1F7FA' }}
			/>
			<Grid
				item
				overflow={{ md: 'scroll', lg: 'auto', xl: 'auto' }}
				justifyContent="space-between"
				width="100%"
			>
				<Grid container gap={1} paddingX={2} flexWrap="nowrap">
					{chips.map((chip) => (
						<Grid item key={chip.id}>
							<Chip
								customStyle={
									hasSelectedOptions(chip.id) ? styles.selectedOptionsChip : styles.chip
								}
								label={chip.name}
								handleClick={(event) => handleClick(event, chip.id)}
								variant="outlined"
							/>
						</Grid>
					))}
					{selectedChip &&
						(getSelectedChip()?.isTreeView ? (
							<TreeView
								open={isOpen}
								loading={loading}
								chip={selectedChip}
								anchorEl={anchorEl}
								isNodeWithObject={true}
								handleClose={handleClose}
								handleReset={resetFilter}
								handleChange={handleTreeViewFilterChange}
								data={options[selectedChip]}
								selectedNodes={selectedValues[selectedChip]?.map(
									(item: TreeNodeDataType) => item.id,
								)}
								selectedNodesWithObject={selectedValues[selectedChip]}
								searchField={
									chips.find((item) => item.id === selectedChip)?.isSearch ?? false
								}
							/>
						) : selectedChip ? (
							<Filter
								open={isOpen}
								loading={loading}
								chip={selectedChip}
								anchorEl={anchorEl}
								handleClose={handleClose}
								handleReset={resetFilter}
								handleSearch={handleSearch}
								handleChange={handleFilterChange}
								options={options[selectedChip]}
								selectedValues={selectedValues[selectedChip]}
								searchField={getSelectedChip()?.isSearch ?? false}
							/>
						) : null)}
				</Grid>
			</Grid>
			{showCopyButton && (
				<Grid item>
					<CopyButton />
				</Grid>
			)}
		</Grid>
	);
};
