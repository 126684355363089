import React, { FC } from 'react';
import Link from 'next/link';
import { Grid, Theme, Typography, Avatar } from '@mui/material';
import { TaskType } from '@/utils/types';
import { AvatarGroup } from './AvatarGroup';
import { optimizeImg } from '@/utils/helpers';

const styles = {
	container: {
		p: 2,
		gap: 2,
		background: (theme: Theme) => theme.borderColor.toolbar,
		borderRadius: '4px',
	},
	ticketContainer: {
		background: (theme: Theme) => theme.palette.common.white,
		borderRadius: '4px',
		p: 2,
		gap: 1,
	},
};

interface LinkedTaskProps {
	heading: string;
	linkedTasks?: TaskType[];
}

export const LinkedTasks: FC<LinkedTaskProps> = ({ heading, linkedTasks }) => {
	return (
		<Grid container sx={styles.container} direction="column">
			<Grid item>
				<Typography
					color="textPrimary"
					fontSize="13px"
					fontWeight={600}
					lineHeight="16px"
				>
					{heading}
				</Typography>
			</Grid>
			{!!linkedTasks?.length &&
				linkedTasks.map((linkedTask: TaskType) => (
					<Grid item key={linkedTask.friendlyId}>
						<Link href={`/tasks/details/TC-${linkedTask.friendlyId}`}>
							<Grid
								container
								alignItems="center"
								justifyContent="space-between"
								sx={styles.ticketContainer}
							>
								<Grid item>
									<Typography
										fontSize="14px"
										lineHeight="21px"
										color={(theme: Theme) => theme.textColor.primary}
									>
										{linkedTask.name}
									</Typography>
								</Grid>
								<Grid item>
									{Array.isArray(linkedTask.assignedUsers) ? (
										<AvatarGroup
											data={linkedTask.assignedUsers as Record<string, any>[]}
										/>
									) : (
										<Avatar
											src={optimizeImg(linkedTask?.assignedUsers || '', 32)}
											alt="Avatar"
											sx={{ width: 32, height: 32 }}
										/>
									)}
								</Grid>
							</Grid>
						</Link>
					</Grid>
				))}
		</Grid>
	);
};
