import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';

export const useAuthService = () => {
	const { get } = useAxios();
	const getLoggedInUserDetails = useCallback(
		(impersonatedUserId?: string) => {
			const path = impersonatedUserId
				? `/users/me?impersonatedUserId=${impersonatedUserId}`
				: '/users/me';
			return get(path);
		},
		[get],
	);

	return { getLoggedInUserDetails };
};
