import React from 'react';
import Link from 'next/link';
import {
	Grid,
	Card as MuiCard,
	CardContent,
	Typography,
	Box,
	IconButton,
	Tooltip,
	CardMedia,
	Theme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { optimizeImg } from '@/utils/helpers';
import { Chip } from './Chip';

type CardPropsType = {
	canEdit?: boolean;
	handleSetAnchorEl: (_event: React.MouseEvent<HTMLElement>) => void;
	statistics: {
		name: string;
		value: number | string;
		link?: string;
	}[];
	status?: string;
	description: string;
	imageUrl: string;
	showTags?: boolean;
	funds?: Record<string, any>[];
};

const styles = {
	card: {
		boxShadow: '0px 7px 20px rgba(40, 41, 61, 0.08);',
		position: 'relative',
		borderRadius: '8px',
		height: '100%',
	},
	cardContent: {
		padding: '0 0 24px',
	},
	cardMenuIcon: {
		height: '40px',
		width: '40px',
		backgroundColor: '#fff',
		position: 'absolute',
		borderRadius: '8px',
		top: '16px',
		right: '16px',
	},
	description: {
		color: '#fff',
		fontSize: '18px',
		fontWeight: 400,
		lineHeight: '21.6px',
		height: '42px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
	},
	cardDetail: {
		px: '16px',
		position: 'absolute',
		bottom: 16,
	},
	status: {
		borderRadius: '4px',
	},
	inActive: { backgroundColor: (theme: Theme) => theme.chip.invited },
	cardText: {
		fontSize: '20px',
		lineHeight: '21.6px',
	},
	cardTextDetail: { fontSize: '12px', lineHeight: '14.4px' },
	chip: {
		height: '22px',
		color: (theme: Theme) => theme.palette.text.secondary,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderColor: (theme: Theme) => theme.palette.text.secondary,
	},

	imageGradientOverlay: {
		top: 0,
		width: '100%',
		height: '100%',
		position: 'absolute',
		background:
			'linear-gradient(179.55deg, rgba(0, 0, 0, 0) 0.39%, rgb(0 0 0 / 50%) 99.61%)',
	},
	image: { height: { xs: 180, xl: 240 } },
};

export const Card = (props: CardPropsType) => {
	const {
		canEdit = true,
		handleSetAnchorEl,
		statistics = [],
		status = '',
		description = '',
		imageUrl,
		showTags = false,
		funds = [],
	} = props;

	const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
		handleSetAnchorEl(event);
	};

	return (
		<MuiCard sx={styles.card}>
			<Box position="relative">
				<Box position="relative">
					<CardMedia
						component="img"
						alt="card-image"
						sx={styles.image}
						src={optimizeImg(imageUrl, 180)}
					/>
					<Box sx={styles.imageGradientOverlay} />
				</Box>

				<Grid
					container
					justifyContent="space-between"
					alignItems="flex-end"
					sx={styles.cardDetail}
					wrap="nowrap"
				>
					<Grid item>
						<Tooltip title={<Typography fontSize="14px">{description}</Typography>}>
							<Typography sx={styles.description}>{description} </Typography>
						</Tooltip>
					</Grid>
					{status && (
						<Grid item>
							<Chip
								label={status}
								customStyle={{
									...styles.status,
									...(status === 'inactive' && styles.inActive),
								}}
							/>
						</Grid>
					)}
				</Grid>
			</Box>

			<CardContent sx={styles.cardContent} onClick={() => {}}>
				{showTags && (
					<Grid container p={2} gap={1}>
						{funds.map((fund: Record<string, any>) => (
							<Chip
								label={fund.name}
								customStyle={styles.chip}
								variant="outlined"
								key={fund.name}
							/>
						))}
					</Grid>
				)}

				<Grid container justifyContent="space-evenly" pt={!showTags ? 3 : 0}>
					{statistics.map(({ name, value, link }) => {
						let statistic = (
							<Grid item textAlign="center" key={name}>
								<Typography sx={styles.cardText}>{value}</Typography>
								<Typography sx={styles.cardTextDetail}>{name}</Typography>
							</Grid>
						);
						if (link) {
							statistic = (
								<Link href={link} key={link}>
									{statistic}
								</Link>
							);
						}
						return statistic;
					})}
				</Grid>
			</CardContent>
			{canEdit && (
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					sx={styles.cardMenuIcon}
					onClick={menuClickHandler}
				>
					<IconButton sx={{ cursor: 'pointer' }}>
						<MoreVertIcon />
					</IconButton>
				</Grid>
			)}
		</MuiCard>
	);
};
