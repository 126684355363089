import { Box, Typography } from '@mui/material';
import React from 'react';
import { Modal } from './Modal';

type ArchiveModalPropsType = {
	isLoading: boolean;
	handleArchived: () => void;
	handleClose: () => void;
	open: boolean;
	title: string;
	btnTitle: string;
	archivedEntity: string;
};

export const ArchiveModal = (props: ArchiveModalPropsType) => {
	const {
		isLoading,
		handleArchived,
		handleClose,
		open,
		title,
		btnTitle,
		archivedEntity,
	} = props;
	return (
		<Modal
			open={open}
			title={title}
			loading={isLoading}
			actionBtnTitle={btnTitle}
			handleClose={handleClose}
			handleSubmit={handleArchived}
		>
			<Typography fontSize="14px" lineHeight="21px">
				Are you sure you want to archive{' '}
				<Box component="span" fontWeight="600" fontSize="13px">
					{archivedEntity}
				</Box>
				? This can be restored at another time.
			</Typography>
		</Modal>
	);
};
