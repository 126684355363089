import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Auth0Provider, AppState, Auth0ProviderOptions } from '@auth0/auth0-react';
import { ThemeProvider, styled } from '@mui/material';
import type { AppProps } from 'next/app';
import { PersistGate } from 'redux-persist/integration/react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { theme } from '@/assets/theme';
import AppContent from '@/app.content';
import { ErrorBoundary, HTMLTitle } from '@/components';
import { store, persistor } from '@/store';
import '@/styles/globals.css';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/utils/constants';
import { getFullUrl } from '@/utils/helpers';

export default function App({ Component, pageProps, router }: AppProps) {
	const [title, setTitle] = useState('');

	useEffect(() => {
		setTitle(Component.displayName ?? Component.name);
	}, [Component]);

	const onRedirectCallback = (appState: AppState | undefined) => {
		if (appState && appState.returnTo) {
			if (appState.returnTo !== '/') {
				router.push(appState.returnTo);
			}
		}
	};

	const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
		'&.notistack-MuiContent-success': {
			backgroundColor: '#36873D',
		},
		'&.notistack-MuiContent-error': {
			backgroundColor: '#B62916',
		},
		'&.notistack-MuiContent-default': {
			backgroundColor: '#69A6B4',
		},
		'&.notistack-MuiContent-info': {
			backgroundColor: '#C77053',
		},
	}));

	const options: Auth0ProviderOptions = {
		domain: AUTH0_DOMAIN,
		clientId: AUTH0_CLIENT_ID,
		useRefreshTokens: true,
		authorizationParams: {
			scope: 'read:users update:users create:users create:connections',
			redirect_uri: getFullUrl('/'),
			audience: AUTH0_AUDIENCE,
		},
	};

	return (
		<ThemeProvider theme={theme}>
			<Auth0Provider
				{...options}
				cacheLocation="localstorage"
				onRedirectCallback={onRedirectCallback}
			>
				<SnackbarProvider
					maxSnack={1}
					style={{
						fontFamily: 'Inter',
					}}
					Components={{
						success: StyledMaterialDesignContent,
						error: StyledMaterialDesignContent,
						default: StyledMaterialDesignContent,
						info: StyledMaterialDesignContent,
					}}
				>
					<Provider store={store}>
						<ErrorBoundary>
							<PersistGate loading={null} persistor={persistor}>
								<HTMLTitle title={title} />
								<AppContent pageProps={pageProps} Component={Component} router={router} />
							</PersistGate>
						</ErrorBoundary>
					</Provider>
				</SnackbarProvider>
			</Auth0Provider>
		</ThemeProvider>
	);
}
