import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';

export const useHelpSectionsService = () => {
	const { get, isLoading } = useAxios();

	const getHelpSections = useCallback(() => get('/help-sections'), [get]);

	return { getHelpSections, isLoadingHelpSections: isLoading };
};
