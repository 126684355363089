import ChartPie from '@/assets/icons/ChartPie.svg';
import Chipboard from '@/assets/icons/Clipboard.svg';
import FolderFilled from '@/assets/icons/FolderFilled.svg';
import HelpCircleIcon from '@/assets/icons/HelpCircleIcon.svg';
import GeneralPartners from '@/assets/icons/GeneralPartners.svg';
import LimitedPartners from '@/assets/icons/LimitedPartners.svg';
import Education from '@/assets/icons/Education.svg';
import PortfolioCompanies from '@/assets/icons/PortfolioCompanies.svg';
import Tasks from '@/assets/icons/Tasks.svg';
import Users from '@/assets/icons/Users.svg';
import { SidebarMenuType } from '@/utils/types';

export const sideMenuOptions: SidebarMenuType[] = [
	{
		name: 'Tasks',
		icon: Tasks,
		path: '/tasks/[view]',
		isMulti: true,
	},
	{
		name: 'Dashboards',
		icon: ChartPie,
		path: '/dashboards',
	},
	{
		name: 'Surveys',
		icon: Chipboard,
		path: '/surveys',
	},
	{
		name: 'Library',
		icon: Education,
		path: '/library',
	},
	{
		name: 'Users',
		icon: Users,
		path: '/users',
	},
	{
		name: 'Files',
		icon: FolderFilled,
		path: '/files',
	},
	{
		name: 'Limited Partners',
		icon: LimitedPartners,
		path: '/limited-partners',
	},
	{
		name: 'General Partners',
		icon: GeneralPartners,
		path: '/general-partners',
	},
	{
		name: 'Portfolio Companies',
		icon: PortfolioCompanies,
		path: '/portfolio-companies',
	},
	{
		name: 'Help Docs',
		icon: HelpCircleIcon,
		path: '/help-docs',
		isMulti: true,
	},
];
