import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IDocOptions } from 'survey-pdf';
import { Grid } from '@mui/material';
import { Modal, Select } from '@/components';
import { isSubmitButtonDisabled } from '@/utils/helpers';

const ORIENTATION_OPTIONS = [
	{ id: 'p', name: 'Portrait' },
	{ id: 'l', name: 'Landscape' },
];

const FORMAT_OPTIONS = [
	{ id: 'a0', name: 'A0' },
	{ id: 'a1', name: 'A1' },
	{ id: 'a2', name: 'A2' },
	{ id: 'a3', name: 'A3' },
	{ id: 'a4', name: 'A4' },
	{ id: 'a5', name: 'A5' },
	{ id: 'a6', name: 'A6' },
	{ id: 'a7', name: 'A7' },
	{ id: 'a8', name: 'A8' },
	{ id: 'a9', name: 'A9' },
	{ id: 'a10', name: 'A10' },
	{ id: 'b0', name: 'B0' },
	{ id: 'b1', name: 'B1' },
	{ id: 'b2', name: 'B2' },
	{ id: 'b3', name: 'B3' },
	{ id: 'b4', name: 'B4' },
	{ id: 'b5', name: 'B5' },
	{ id: 'b6', name: 'B6' },
	{ id: 'b7', name: 'B7' },
	{ id: 'b8', name: 'B8' },
	{ id: 'b9', name: 'B9' },
	{ id: 'b10', name: 'B10' },
	{ id: 'c0', name: 'C0' },
	{ id: 'c1', name: 'C1' },
	{ id: 'c2', name: 'C2' },
	{ id: 'c3', name: 'C3' },
	{ id: 'c4', name: 'C4' },
	{ id: 'c5', name: 'C5' },
	{ id: 'c6', name: 'C6' },
	{ id: 'c7', name: 'C7' },
	{ id: 'c8', name: 'C8' },
	{ id: 'c9', name: 'C9' },
	{ id: 'c10', name: 'C10' },
	{ id: 'dl', name: 'DL' },
	{ id: 'letter', name: 'Letter' },
	{ id: 'government-letter', name: 'Government Letter' },
	{ id: 'legal', name: 'Legal' },
	{ id: 'junior-legal', name: 'Junior Legal' },
	{ id: 'ledger', name: 'Ledger' },
	{ id: 'tabloid', name: 'Tabloid' },
	{ id: 'credit-card', name: 'Credit Card' },
];

const FONT_SIZE_OPTIONS = [
	{ id: '8', name: '8' },
	{ id: '10', name: '10' },
	{ id: '12', name: '12' },
	{ id: '14', name: '14' },
	{ id: '16', name: '16' },
	{ id: '18', name: '18' },
	{ id: '20', name: '20' },
];

type PdfConfigModalPropsType = {
	open: boolean;
	handleClose: () => void;
	onDownloadPdf: (values: IDocOptions, resetForm: () => void) => void;
};

// Override IDocOptions fontSize type to string because of Select component value type
type IDocOptionsWithStringFontSize = Omit<IDocOptions, 'fontSize'> & { fontSize: string };

export const PdfConfigModal = ({
	open,
	handleClose,
	onDownloadPdf,
}: PdfConfigModalPropsType) => {
	const initialConfigState: IDocOptionsWithStringFontSize = {
		orientation: 'p',
		format: 'a3',
		fontSize: '14',
	};
	const validationSchema = Yup.object({
		orientation: Yup.string().required('Orientation is required'),
		format: Yup.string().required('Format is required'),
		fontSize: Yup.string().required('Font Size is required'),
	});

	const formik = useFormik({
		initialValues: initialConfigState,
		enableReinitialize: true,
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			onDownloadPdf({ ...values, fontSize: +values.fontSize }, resetForm);
		},
	});

	const handleCloseModal = () => {
		formik.resetForm();
		handleClose();
	};

	return (
		<Modal
			open={open}
			title="PDF Settings"
			actionBtnTitle="Download"
			handleClose={handleCloseModal}
			handleSubmit={formik.handleSubmit}
			disabledBtn={isSubmitButtonDisabled(formik.touched, formik.errors)}
		>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<Select
						required
						id="orientation"
						name="orientation"
						label="Orientation"
						options={ORIENTATION_OPTIONS}
						onChange={formik.handleChange}
						placeholder="Select Orientation"
						value={formik.values.orientation}
						error={formik.touched.orientation && Boolean(formik.errors.orientation)}
						helperText={formik.touched.orientation && formik.errors.orientation}
					/>
				</Grid>
				<Grid item xs={6}>
					<Select
						required
						id="format"
						name="format"
						label="Format"
						options={FORMAT_OPTIONS}
						onChange={formik.handleChange}
						placeholder="Select Format"
						value={formik.values.format}
						error={formik.touched.format && Boolean(formik.errors.format)}
						helperText={formik.touched.format && formik.errors.format}
					/>
				</Grid>
				<Grid item xs={6}>
					<Select
						required
						id="fontSize"
						name="fontSize"
						label="Font Size"
						options={FONT_SIZE_OPTIONS}
						onChange={formik.handleChange}
						placeholder="Select Font Size"
						value={formik.values.fontSize}
						error={formik.touched.fontSize && Boolean(formik.errors.fontSize)}
						helperText={formik.touched.fontSize && formik.errors.fontSize}
					/>
				</Grid>
			</Grid>
		</Modal>
	);
};
