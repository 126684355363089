import React, { FC, useState, ChangeEvent } from 'react';
import { Avatar, Box, Grid, Typography, Theme, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tabs } from './Tabs';
import { Button } from './Button';
import { Menu } from './Menu';

type InsightsProps = {
	labels: string[];
};

const styles = {
	avatar: { height: '24px', width: '24px' },
	messageBox: {
		background: (theme: Theme) => theme.palette.secondary.main,
		padding: '16px',
		borderRadius: '8px',
	},
	viewReplyButton: {
		p: '15px 20px',
		background: 'transparent',
		color: (theme: Theme) => theme.palette.secondary.light,
		fontWeight: 600,
		textTransform: 'capitalize',
		boxShadow: 'none',
		'&:hover': {
			background: 'initial',
			boxShadow: 'none',
		},
	},
	createButton: {
		p: '15px 20px',
		background: (theme: Theme) => theme.palette.primary.main,
		color: (theme: Theme) => theme.palette.common.white,
	},
};

export const Insights: FC<InsightsProps> = ({ labels }) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
		setSelectedTab(newValue);
	};

	const handleSetAnchorEl = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Box sx={{ width: '320px' }}>
			<Tabs
				padding={0}
				tabHeaderPadding="0px"
				selectedTab={selectedTab}
				handleTabChange={handleTabChange}
				labels={labels}
				components={[
					<Grid container key="neliAI" p={3}>
						<Grid item>
							<Grid container wrap="nowrap" gap="8px">
								<Grid item>
									<Avatar
										alt="Remy Sharp"
										src="https://mui.com/static/images/avatar/1.jpg"
										sx={styles.avatar}
									/>
								</Grid>
								<Grid item sx={styles.messageBox}>
									<Typography
										mb={1}
										fontSize="14px"
										lineHeight="21px"
										color="text.primary"
									>
										Lorem Ipsum is simply dummy text of the printing and typesetting
										industry. Lorem Ipsum has been the industrys standard dummy text
									</Typography>
									<Typography fontSize="12px" color={'text.secondary'}>
										Aug 10, 2027 | 4:00 PM CDT
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container justifyContent="flex-end">
								<Button
									text="view Reply"
									customStyles={styles.viewReplyButton}
									onClick={() => {}}
								/>
							</Grid>
						</Grid>
					</Grid>,
					<Grid container key="insights" flexDirection="column">
						<Grid item p="16px 24px">
							<Button
								text="Create Insight"
								customStyles={styles.createButton}
								fullWidth
								onClick={() => {}}
							/>
						</Grid>
						<Grid
							item
							sx={{
								borderTop: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
							}}
						>
							<Grid container p={3} spacing="16px" wrap="nowrap">
								<Grid item>
									<Typography
										fontSize="12px"
										lineHeight="14px"
										color={(theme: Theme) => theme.textColor.primary}
										mb={1}
									>
										Aug 10, 2027
									</Typography>
									<Typography
										mt={'4px'}
										fontSize="12px"
										color={(theme: Theme) => theme.textColor.primary}
										fontWeight={600}
										lineHeight="16px"
									>
										Lorem Ipsum is simply dummy text of the printing and typesetting
										industry. Lorem Ipsum has been the industrys standard dummy text
									</Typography>
								</Grid>
								<Grid item>
									<IconButton onClick={handleSetAnchorEl}>
										<MoreVertIcon
											sx={{
												fill: (theme: Theme) => theme.borderColor.divider,
												width: 20,
												height: 20,
											}}
										/>
									</IconButton>
								</Grid>
								<Menu
									items={[
										{
											name: 'View',
											onClick: () => {},
										},
										{
											name: 'Delete',
											onClick: () => {},
										},
										{
											name: 'Reopen ',
											onClick: () => {},
										},
									]}
									anchorEl={anchorEl}
									handleClose={() => {
										setAnchorEl(null);
									}}
								/>
							</Grid>
						</Grid>
					</Grid>,
				]}
			/>
		</Box>
	);
};
