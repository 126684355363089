import React from 'react';
import { Grid } from '@mui/material';

type EllipsisTextPropsType = {
	text: string;
	customStyle?: Record<string, any>;
};

const styles = {
	text: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: 100,
	},
};

export const EllipsisText = (props: EllipsisTextPropsType) => {
	const { text, customStyle = {} } = props;

	return <Grid sx={[styles.text, customStyle]}>{text}</Grid>;
};
