import React from 'react';
import { Grid, InputAdornment, Typography, Theme } from '@mui/material';
import Image from 'next/image';
import { Button } from './Button';
import { TextField } from './TextField';
import SearchIcon from '@/assets/icons/Search.svg';

type SecondaryHeaderPropsType = {
	showSearch?: boolean;
	showPrimaryBtn?: boolean;
	primaryBtnText?: string;
	primaryBtnHandler?: () => void;
	title?: string;
	searchPlaceholder?: string;
	leftCustomElement?: React.ReactNode;
	rightCustomElement?: React.ReactNode;
	height?: number;
	handleChangeSearch?: (_arg: string) => void;
	searchText?: string;
};

const styles = {
	header: {
		padding: '16px 24px',
		backgroundColor: '#fff',
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
	},
	title: {
		fontSize: '24px',
		fontWeight: 600,
		fontFamily: 'Poppins',
		lineHeight: '28.8px',
		color: '#425057',
	},
	textField: {
		'& .MuiInputBase-root': {
			height: '46px',
			width: '400px',
			paddingLeft: '12px',
			input: {
				'&::placeholder': {
					color: (theme: Theme) => theme.palette.text.secondary,
					opacity: 1,
				},
				height: 'inherit',
				fontSize: '13px',
				lineHeight: '15.6px',
				padding: 0,
			},
			fieldset: {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
			'&.Mui-focused fieldset': {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
			'&:hover fieldset': {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
		},
	},
};

export const SecondaryHeader = (props: SecondaryHeaderPropsType) => {
	const {
		showSearch = false,
		showPrimaryBtn = true,
		primaryBtnText = '',
		primaryBtnHandler = () => {},
		title = '',
		searchPlaceholder = '',
		leftCustomElement,
		rightCustomElement,
		height,
		handleChangeSearch,
		searchText,
	} = props;
	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			sx={{ ...styles.header, height: height || '78px' }}
		>
			<Grid item>
				<Grid container gap={title || leftCustomElement ? 2 : 0} alignItems="center">
					<Grid item>
						{leftCustomElement ? (
							leftCustomElement
						) : (
							<Typography sx={styles.title}>{title}</Typography>
						)}
					</Grid>
					{showSearch && (
						<Grid item>
							<TextField
								placeholder={searchPlaceholder}
								customStyle={styles.textField}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Image src={SearchIcon} height={15} width={15} alt="search-icon" />
										</InputAdornment>
									),
								}}
								value={searchText}
								onChange={(event) => handleChangeSearch!(event.target.value)}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item>
				{rightCustomElement
					? rightCustomElement
					: showPrimaryBtn && (
							<Button text={primaryBtnText} onClick={primaryBtnHandler} />
					  )}
			</Grid>
		</Grid>
	);
};
