import { forwardRef } from 'react';
import { US_PHONE_MASK } from '@/utils/constants';
import MaskedInput from 'react-text-mask';

export const PhoneNumberInput = forwardRef((props: Record<string, any>, _ref) => {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={inputRef}
			mask={US_PHONE_MASK}
			placeholderChar={'\u2000'}
			keepCharPositions
			guide={false}
		/>
	);
});

PhoneNumberInput.displayName = 'PhoneNumberInput';
