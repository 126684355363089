import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';

export const useHelpDocsService = () => {
	const { get, isLoading } = useAxios();

	const getHelpDocs = useCallback(
		(query: { helpSectionId?: string } = {}) => get('/help-docs', query),
		[get],
	);

	const getHelpDoc = useCallback((id: string) => get(`/help-docs/${id}`), [get]);

	return { getHelpDocs, getHelpDoc, isLoadingHelpDocs: isLoading };
};
