import React from 'react';
import Image from 'next/image';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import {
	IconButton,
	Typography,
	Box,
	InputLabel,
	Grid,
	Divider,
	Theme,
} from '@mui/material';
import { Button } from './Button';
import useMixpanel from '@/hooks/useMixpanel';
import { formatBytes, optimizeImg } from '@/utils/helpers';
import DeleteIcon from '@/assets/icons/Trash.svg';
import UploadIcon from '@/assets/icons/Upload.svg';
import CSVFileIcon from '@/assets/icons/CSVFile.svg';
import { CircularProgress } from '@mui/material';

const makeStyles = ({ allowedImage }: { allowedImage: boolean }) => ({
	documentDropzone: {
		border: '1px solid #61737C',
		borderRadius: '8px',
		padding: '16px 24px',
		textAlign: 'center',
		backgroundColor: '#F9FEFF',
		cursor: 'pointer',
		height: allowedImage ? 'unset' : '300px',
	},
	label: {
		fontSize: 14,
		fontWeight: 600,
		paddingBottom: 1,
		color: 'text.primary',
		lineHeight: '15.6px',
	},
	image: {
		height: '150px',
		width: '100%',
		objectFit: 'cover',
	},
	removeBtn: {
		color: '#C65B54',
		borderColor: '#C65B54',
		'&:hover': {
			color: '#C65B54',
			borderColor: '#C65B54',
		},
	},
	uploadButton: {
		padding: '10px 20px',
		borderRadius: '4px',
		border: (theme: Theme) => `1px solid ${theme.borderColor.button}`,
		fontSize: '13px',
		lineHeight: '16px',
		color: (theme: Theme) => theme.palette.text.secondary,
	},
	uploadContainer: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		height: '300px',
		overflow: 'auto',
	},
	fileContainer: {
		padding: '13.5px 16px',
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
});

type UploadFilePropsType = {
	name: string;
	label: string;
	error?: boolean;
	helperText?: any;
	loading?: boolean;
	progress?: number;
	required?: boolean;
	secondaryText?: string;
	allowedImage?: boolean;
	files: File[] | File | string;
	handleFiles?: (filesData: { files: File[] }) => void;
	onChange: (_event: { target: { name: string; value: File[] | File | string } }) => void;
};

export const UploadFile = ({
	onChange,
	files,
	name = '',
	label = '',
	handleFiles,
	progress = 0,
	error = false,
	helperText = '',
	loading = false,
	required = false,
	secondaryText = '',
	allowedImage = true,
}: UploadFilePropsType) => {
	const styles = makeStyles({ allowedImage });
	const { track } = useMixpanel();

	// Window used because of the useDropzone hook
	const windowObj = window as any;

	const onClick = () => {
		windowObj.uploadMethod = 'clicked';
	};

	const onDragEnter = () => {
		windowObj.uploadMethod = 'dragged';
	};

	const onDrop = (acceptedFiles: File[]) => {
		track('Files Chosen', {
			method: windowObj.uploadMethod,
			files: acceptedFiles,
		});
		if (handleFiles) {
			handleFiles({ files: acceptedFiles });
		} else {
			onChange({
				target: { name, value: allowedImage ? acceptedFiles[0] : acceptedFiles },
			});
		}
	};

	const removedHandler = (indexToRemove?: number) => {
		let updatedFiles: File[] = [];
		if (Array.isArray(files)) {
			if (indexToRemove !== undefined) {
				updatedFiles = [...files];
				updatedFiles.splice(indexToRemove, 1);
			}
		} else {
			updatedFiles = [];
		}
		onChange({ target: { name, value: updatedFiles } });
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDragEnter,
		onDrop,
		...(allowedImage && {
			accept: {
				'application/file': ['.jpeg', '.png', '.jpg', '.gif'],
			},
		}),
		...(allowedImage && { maxFiles: 1 }),
	});

	return (
		<Box onClick={onClick}>
			<Grid container alignItems="center" gap={1} pb={1}>
				<Grid item>
					<InputLabel sx={styles.label}>{`${label} ${required ? '*' : ''}`}</InputLabel>
				</Grid>
				<Grid item>
					{loading ? (
						<CircularProgress size={20} value={progress} variant="determinate" />
					) : null}
				</Grid>
			</Grid>
			{allowedImage && !isEmpty(files) ? (
				<Box
					component="img"
					src={
						typeof files === 'string'
							? optimizeImg(files, 150)
							: URL.createObjectURL(files as File)
					}
					sx={styles.image}
				/>
			) : !allowedImage && !isEmpty(files) ? (
				<Box sx={styles.uploadContainer}>
					<Grid
						container
						{...getRootProps()}
						justifyContent="space-between"
						alignItems="center"
						p={2}
					>
						<Grid item>
							<Typography color="text.primary" fontSize="13px">
								Select files or drag-and-drop to upload
							</Typography>
						</Grid>
						<Grid item>
							<input {...getInputProps()} />
							<Button
								text={'Add Files'}
								variant="outlined"
								customStyles={styles.uploadButton}
								onClick={() => {}}
								disabled={loading}
							/>
						</Grid>
					</Grid>
					<Divider />
					{Array.isArray(files) &&
						files.map((file: File, index: number) => (
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
								key={index}
								sx={styles.fileContainer}
							>
								<Grid item>
									<Grid container>
										<Grid item>
											<Image src={CSVFileIcon} alt={'CSVFile'} width={40} />
										</Grid>
										<Grid item>
											<Typography fontSize="14px" color="#61737C">
												{file.name}
											</Typography>
											<Typography fontSize="14px" color="#667085">
												{formatBytes(file.size)}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<IconButton onClick={() => removedHandler(index)} disabled={loading}>
										<Image src={DeleteIcon} alt={'delete'} width={24} />
									</IconButton>
								</Grid>
							</Grid>
						))}
				</Box>
			) : (
				<Grid
					container
					{...getRootProps()}
					sx={styles.documentDropzone}
					alignItems="center"
				>
					<Grid item xs={12}>
						<input {...getInputProps()} />
						<IconButton>
							<Image src={UploadIcon} alt="UploadIcon-button" />
						</IconButton>
						<Box width="216px" margin="auto">
							<Typography fontSize="14px" color="#5493A1">
								<Box
									component="span"
									sx={{
										fontSize: '14px',
										color: '#2F7281',
									}}
								>
									Click to upload{' '}
								</Box>
								or drag and drop
							</Typography>
							<Typography fontSize="10px" color="#5493A1" textAlign="center">
								{secondaryText}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			)}
			{error && (
				<Typography
					sx={{ fontSize: '14px', color: 'error.main' }}
					py={1}
					lineHeight="12px"
				>
					{helperText}
				</Typography>
			)}
			{allowedImage && (
				<Typography fontSize="10px" color="text.secondary" py={1} lineHeight="12px">
					Allowed *.jpeg, *.jpg, *.png, *.gif
				</Typography>
			)}
			{allowedImage && !isEmpty(files) && (
				<Button
					text="Remove Photo"
					onClick={removedHandler}
					variant="outlined"
					customStyles={styles.removeBtn}
				/>
			)}
		</Box>
	);
};
