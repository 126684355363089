import React from 'react';
import { Box, Theme, Typography } from '@mui/material';

type BadgePropsType = {
	count: string | number;
};

const styles = {
	badge: {
		backgroundColor: (theme: Theme) => theme.textColor.secondary,
		borderRadius: '20px',
		height: 'fit-content',
		padding: '5.5px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: '25px',
		textAlign: 'center',
	},
};

export const Badge = ({ count }: BadgePropsType) => {
	return (
		<Box sx={styles.badge}>
			<Typography fontSize="12px" color="white" lineHeight="14px">
				{count}
			</Typography>
		</Box>
	);
};
