import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { TextField } from './TextField';

type AddressFieldPropsType = {
	label?: string;
	type?: 'text' | 'date' | 'password';
	loading?: boolean;
	placeholder?: string;
	name?: string;
	searchName?: string;
	onChange: (
		_event:
			| React.ChangeEvent<HTMLInputElement>
			| { target: { name: string; value: string } },
		_isSelected?: boolean,
	) => void;
	onBlur?: (_event: React.FocusEvent<HTMLInputElement, Element>) => void;
	value?: string;
	required?: boolean;
	id?: string;
	error?: boolean;
	helperText?: string | boolean;
};

export const AddressField = ({
	label = '',
	loading = false,
	placeholder = '',
	name = '',
	onChange,
	value = '',
	...props
}: AddressFieldPropsType) => {
	const { ref } = usePlacesWidget({
		apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API,
		onPlaceSelected: (place: Record<string, any>) => {
			const value = place.formatted_address;
			const isSelected = true;
			onChange({ target: { name, value } }, isSelected);
		},
	});
	return (
		<TextField
			name={name}
			value={value}
			label={label}
			variant="outlined"
			placeholder={placeholder}
			inputRef={ref}
			onChange={onChange}
			{...props}
		/>
	);
};
