import { ChangeEvent } from 'react';
import useMixpanel from '@/hooks/useMixpanel';
import {
	Box,
	Theme,
	Pagination as MuiPagination,
	Select as MuiSelect,
	MenuItem,
} from '@mui/material';

type PaginationPropsType = {
	totalPages: number;
	currentPage: number;
	take?: number;
	onChangeHandler: (event: ChangeEvent<unknown>, page: number) => void;
	onTakeChange?: (event: any) => void;
};

const styles = {
	container: {
		position: 'relative',
		borderTop: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
	},
	pagination: {
		'& ul': {
			justifyContent: 'center',
		},
		'& .MuiButtonBase-root': {
			color: '#1F2738',
			height: '24px',
			minWidth: '24px',
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '17.07px',
		},
		'& .Mui-selected': {
			color: '#fff',
		},
		'& .MuiSvgIcon-root': {
			height: '24px',
			width: '24px',
			color: '#789AC1',
		},
	},
	takeContainer: {
		position: 'absolute',
		left: 35,
		bottom: 16,
	},
	take: {
		height: 40,
		fontSize: 14,
	},
};

export const Pagination = ({
	totalPages,
	currentPage,
	take,
	onChangeHandler,
	onTakeChange,
}: PaginationPropsType) => {
	const { track } = useMixpanel();

	const handlePageChange = (event: any, page: number) => {
		onChangeHandler(event, page);
		track('Page Changed', { new_page: page });
	};

	const handleTakeChange = (event: any) => {
		onTakeChange!(event);
		track('Total Items per Page Changed', { total_items: event.target.value });
	};

	return (
		<Box py={3} sx={styles.container}>
			<Box sx={styles.takeContainer}>
				{take && (
					<MuiSelect onChange={handleTakeChange} value={take} sx={styles.take}>
						<MenuItem value={10}>10</MenuItem>
						<MenuItem value={25}>25</MenuItem>
						<MenuItem value={50}>50</MenuItem>
						<MenuItem value={100}>100</MenuItem>
					</MuiSelect>
				)}
			</Box>
			<MuiPagination
				count={totalPages}
				page={currentPage}
				onChange={handlePageChange}
				shape="rounded"
				color="primary"
				sx={styles.pagination}
			/>
		</Box>
	);
};
