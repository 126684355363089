import * as React from 'react';
import Image from 'next/image';
import {
	MenuItem,
	Typography,
	Theme,
	InputLabel,
	FormControl,
	FormHelperText,
	Select as MuiSelect,
	Icon,
	IconButton,
	Box,
} from '@mui/material';
import DropdownIcon from '@/assets/icons/Dropdown.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { isEmpty } from 'lodash';

type SelectPropsType = {
	label: string;
	placeholder: string;
	options: Record<string, any>[];
	onChange: (_arg: { target: { name: string; value: any } }) => void;
	onBlur?: (_event: React.FocusEvent<HTMLInputElement, Element>) => void;
	value?: '' | any;
	optionKey?: string;
	name: string;
	required?: boolean;
	error?: boolean;
	helperText?: string | boolean;
	id?: string;
	multiple?: boolean;
	disabled?: boolean;
};

const styles = {
	label: {
		fontSize: 13,
		fontWeight: 600,
		paddingBottom: 1,
		color: 'text.primary',
		lineHeight: '15.6px',
	},
	select: {
		color: (theme: Theme) => theme.palette.text.primary,
		fontSize: 13,
		borderRadius: 2,
		'& .MuiSelect-outlined': {
			padding: '11.5px',
		},
		'& fieldset': {
			borderColor: (theme: Theme) => theme.palette.text.primary,
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: (theme: Theme) => theme.palette.primary.main,
		},
	},
	placeholder: {
		'& .MuiSelect-outlined': {
			color: (theme: Theme) => theme.palette.text.secondary,
		},
	},
	textEllipsis: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	menu: {
		'& .MuiMenu-list': {
			py: 0,
		},
	},
};

export function Select({
	label,
	placeholder,
	options = [],
	onChange,
	value = '',
	optionKey = 'name',
	name,
	required = false,
	helperText,
	error,
	multiple = false,
	id,
	disabled = false,
	...props
}: SelectPropsType) {
	const renderValue = (selected: Record<string, any>) => {
		if (!((typeof selected === 'number' && isNaN(selected)) || selected?.length)) {
			return placeholder;
		} else if (multiple) {
			return options
				?.filter((item) => selected.includes(item.id))
				.map((item) => item[optionKey])
				.join(', ');
		} else {
			return options.find((option) => option.id === selected)?.[optionKey];
		}
	};

	return (
		<>
			<InputLabel sx={styles.label}>{`${label} ${required ? '*' : ''}`}</InputLabel>
			<FormControl error={error} fullWidth>
				<MuiSelect
					id={id}
					disabled={disabled}
					error={error}
					multiple={multiple}
					displayEmpty
					value={value}
					name={name}
					renderValue={renderValue}
					onChange={(event) => {
						onChange({ target: { name, value: event.target.value } });
					}}
					IconComponent={(props) => (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{!isEmpty(value) && (
								<IconButton
									onClick={() => {
										onChange({ target: { name, value: '' } });
									}}
									sx={{ mr: '1.5rem' }}
								>
									<ClearIcon fontSize="small" />
								</IconButton>
							)}
							<Icon {...props}>
								<Image src={DropdownIcon} alt="dropdown" />
							</Icon>
						</Box>
					)}
					sx={{
						...styles.select,
						color: !value ? styles.placeholder['& .MuiSelect-outlined'].color : '',
					}}
					MenuProps={{ sx: styles.menu }}
					placeholder={placeholder}
					{...props}
				>
					{options.length < 1 ? (
						<MenuItem disabled divider sx={{ padding: 1, textTransform: 'capitalize' }}>
							<Typography sx={styles.textEllipsis}>{'No options'}</Typography>
						</MenuItem>
					) : (
						options.map((option) => (
							<MenuItem
								divider
								key={option['id']}
								value={option['id']}
								sx={{ padding: 1, textTransform: 'capitalize' }}
							>
								<Typography sx={styles.textEllipsis}>{option[optionKey]}</Typography>
							</MenuItem>
						))
					)}
				</MuiSelect>
				<FormHelperText sx={{ fontWeight: 600, marginLeft: 0 }}>
					{helperText}
				</FormHelperText>
			</FormControl>
		</>
	);
}
