import { FormControlLabel, Switch as MuiSwitch, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type SwitchPropsType = {
	name: string;
	label: string;
	checked: boolean;
	customStyles?: any;
	onChange: any;
	disabled?: boolean;
};

const styles = {
	switch: {
		'& .MuiTypography-root': { fontSize: '14px', fontWeight: 600 },
		margin: 0,
		color: (theme: Theme) => theme.palette.text.primary,
	},
};

const CustomSwitch = styled(MuiSwitch)(({ theme }) => ({
	width: 32,
	height: 16,
	padding: 0,
	marginRight: 5,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(13px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.primary.main,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.text.primary,
		boxSizing: 'border-box',
	},
}));

export const Switch = ({
	label = '',
	customStyles = {},
	checked = false,
	onChange,
	name,
	disabled = false,
}: SwitchPropsType) => {
	return (
		<FormControlLabel
			control={<CustomSwitch name={name} />}
			label={label}
			sx={[styles.switch, customStyles]}
			checked={checked}
			onChange={(e) => onChange(e)}
			disabled={disabled}
		/>
	);
};
