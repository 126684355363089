import React, { ErrorInfo, FC, useCallback, useEffect } from 'react';
import Image from 'next/image';
import { isEmpty } from 'lodash';
import Bugsnag from '@bugsnag/js';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary';
import BugsnagPluginReact, {
	BugsnagErrorBoundary,
	BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';
import { Button } from '@/components';
import { UserType } from '@/utils/types';
import { RootState } from '@/store/slices';
import BrokenBulb from '../assets/images/brokenBulb.png';
import TableclothLogo from '../assets/icons/TableclothLogo.svg';
import { BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE } from '@/utils/constants';

let BugsnagErrorBoundaryComponent: BugsnagErrorBoundary;

const initializeBugsnag = () => {
	if (BugsnagErrorBoundaryComponent) return;
	Bugsnag.start({
		collectUserIp: false,
		apiKey: BUGSNAG_API_KEY,
		plugins: [new BugsnagPluginReact()],
		user: {},
		enabledReleaseStages: ['production'],
		releaseStage: BUGSNAG_RELEASE_STAGE,
	});

	const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
	BugsnagErrorBoundaryComponent = plugin.createErrorBoundary(React);
};

const styles = {
	main: {
		paddingTop: '5em',
		height: '100vh',
		bgcolor: (theme: Theme) => theme.palette.common.white,
	},
	heading: {
		fontSize: '28px',
		fontWeight: 600,
		fontFamily: 'Poppins',
	},
	text: {
		fontSize: '20px',
		color: 'text.primary',
	},
};

interface ErrorBoundaryFallbackProps {
	error: Error;
	info: ErrorInfo;
	clearError: () => void;
}

interface ErrorBoundaryProps {
	children: React.ReactNode;
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = withErrorBoundary(({ children }) => {
	const [error, resetError] = useErrorBoundary();
	const { data: user } = useSelector((state: RootState) => state.me);

	const setUserContextToBugsnag = useCallback((error: Error, user: UserType) => {
		const { id: userId, email, userName } = user;
		Bugsnag.notify(error, (event) => {
			event.setUser(event.getUser().id, email, userName);
			event.addMetadata('user', { userId });
		});
	}, []);

	useEffect(() => {
		if (error && !isEmpty(user)) {
			setUserContextToBugsnag(error as Error, user);
		}
	}, [error, user, setUserContextToBugsnag]);

	const FallbackComponent: FC<ErrorBoundaryFallbackProps> = () => {
		return (
			<Box sx={styles.main}>
				<Grid container direction="column" alignContent="center" gap={2}>
					<Grid item>
						<Grid item container justifyContent="center">
							<Image priority src={TableclothLogo} alt="logo" width={100} />
						</Grid>
					</Grid>
					<Grid item>
						<Grid item container justifyContent="center">
							<Image src={BrokenBulb} width={250} alt="image" />
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction="column" gap={2}>
							<Grid item container direction="column">
								<Typography sx={styles.heading}>Oops...</Typography>
								<Typography sx={styles.text}>
									Something went wrong on our end 😮‍💨
								</Typography>
							</Grid>
							<Grid item container direction="column">
								<Button
									onClick={() => {
										resetError();
									}}
									text="Try again ?"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	};

	if (error) {
		initializeBugsnag();
		return (
			<BugsnagErrorBoundaryComponent FallbackComponent={FallbackComponent}>
				{children}
			</BugsnagErrorBoundaryComponent>
		);
	}

	return <>{children}</>;
});
