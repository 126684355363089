import { useRouter } from 'next/router';
import Image from 'next/image';
import React from 'react';
import { Button } from './Button';
import useNotification from '@/hooks/useNotification';
import CopyIcon from '@/assets/icons/Copy.svg';

interface CopyButtonProps {
	iconOnly?: boolean;
	path?: string;
}

export const CopyButton = ({ iconOnly = false, path }: CopyButtonProps) => {
	const router = useRouter();
	const { sendNotification } = useNotification();

	const handleCopy = () => {
		const generatePath = `${window.location.origin}${path || router.asPath}`;
		navigator.clipboard.writeText(generatePath);
		sendNotification({ msg: 'Link copied to clipboard', variant: 'success' });
	};

	return (
		<>
			{iconOnly ? (
				<Image
					src={CopyIcon}
					alt="CopyIcon"
					onClick={handleCopy}
					style={{ cursor: 'pointer' }}
				/>
			) : (
				<Button
					text="Copy"
					startIcon={<Image src={CopyIcon} alt="CopyIcon" />}
					variant="text"
					onClick={handleCopy}
				/>
			)}
		</>
	);
};
