import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Grid, Theme, Typography } from '@mui/material';
import { GET_AUTH_ME } from '@/store/actions';
import { useAuthService } from '@/store/services/auth.service';
import { useUserService } from '@/store/services/user.service';
import { RootState } from '@/store/slices';
import { Button } from './Button';

const styles = {
	links: {
		margin: '12px 0',
	},
	link: {
		color: (theme: Theme) => theme.palette.primary.main,
		fontSize: '14px',
		marginBottom: '5px',
	},
};

export const TermsAndConditions = () => {
	const dispatch = useDispatch();
	const { data: user } = useSelector((state: RootState) => state.me);
	const { updateUser } = useUserService();
	const { getLoggedInUserDetails } = useAuthService();

	const acceptTerms = async () => {
		await updateUser(user!.id, { acceptedTermsAndConditions: true });
		dispatch(GET_AUTH_ME({ getLoggedInUserDetails }));
	};

	return (
		<Dialog open={!user?.acceptedTermsAndConditions} maxWidth="xs" fullWidth>
			<Grid container justifyContent="space-between" alignItems="center" p={2}>
				<Typography fontWeight={600} fontSize="18px">
					Updated Terms and Conditions
				</Typography>
			</Grid>
			<DialogContent sx={{ pb: 3 }} dividers>
				<Typography textAlign="justify" align="center" fontSize="14px" marginBottom={1}>
					Please note that our terms and conditions of use have changed recently. By using
					Tablecloth you agree to those terms.
					<Grid sx={styles.links}>
						<Link
							href="https://about.tablecloth.io/legal/dpa"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Typography sx={styles.link}>Click here to read our updated DPA</Typography>
						</Link>
						<Link
							href="https://about.tablecloth.io/legal/eula"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Typography sx={styles.link}>
								Click here to read our updated EULA
							</Typography>
						</Link>
						<Link
							href="https://about.tablecloth.io/legal/privacy-policy"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Typography sx={styles.link}>
								Click here to read our updated Privacy Policy
							</Typography>
						</Link>
					</Grid>
					If you have any questions or concerns, please contact support. Click below to
					confirm you have read and accept the new terms.
				</Typography>
				<Grid container item justifyContent="center">
					<Button text="Accept" onClick={acceptTerms} />
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
