// This component is used to clamp the text to a max number of lines, adding an ellipsis
// at the end of the last line if the text overflows. The advantage of this over
// -webkit-box is that it doesn't leave an empty space on the right of the component

import React, { useState, useEffect, useRef } from 'react';
import { Box, Tooltip } from '@mui/material';

interface ClampLinesProps {
	text: string;
	maxLines: number;
	maxWidth: number;
	tooltipText: string;
	textStyle: React.CSSProperties;
}

const styles = {
	text: {
		fontFamily: 'Inter',
	},
};

export const ClampLines: React.FC<ClampLinesProps> = ({
	text,
	maxLines,
	maxWidth,
	tooltipText,
	textStyle,
}) => {
	const [lines, setLines] = useState<string[]>([]);
	const measureRef = useRef<HTMLSpanElement>(null);
	const textStyles = { ...styles.text, ...textStyle };

	// Break the text into multiple p elements based on the max width and max lines
	useEffect(() => {
		// setTimeout used to ensure it runs after other page components are loaded,
		// which is needed on full page load. Open to a better solution.
		setTimeout(() => {
			const words = text.split(' ');
			const newLines = [];
			let line = '';

			for (let i = 0; i < words.length; i++) {
				if (measureRef.current) {
					measureRef.current.textContent = `${line} ${words[i]}`;
					if (measureRef.current.offsetWidth > maxWidth && i !== 0) {
						if (newLines.length === maxLines - 1) {
							newLines.push(`${line}...`);
							break;
						} else {
							newLines.push(line.trim());
							line = words[i];
						}
					} else {
						line += ` ${words[i]}`;
					}
				}
			}

			if (newLines.length < maxLines) {
				newLines.push(line.trim());
			}

			setLines(newLines);
			if (measureRef.current) {
				measureRef.current.textContent = '';
			}
		}, 100);
	}, [text, maxLines, maxWidth]);

	return (
		<Tooltip title={tooltipText} placement="bottom">
			<Box style={{ maxWidth: `${maxWidth}px` }}>
				{lines.map((line, index) => (
					<p key={index} style={textStyles}>
						{line}
					</p>
				))}
				<span
					ref={measureRef}
					style={{
						...textStyles,
						visibility: 'hidden',
						whiteSpace: 'nowrap',
						position: 'absolute',
					}}
				/>
			</Box>
		</Tooltip>
	);
};
