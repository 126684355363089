import Head from 'next/head';

type FiltersPropsType = {
	title: string;
};

export const HTMLTitle = ({ title = '' }: FiltersPropsType) => {
	return (
		<Head>
			<title>{title ? `${title} - Tablecloth` : 'Tablecloth'}</title>
		</Head>
	);
};
