import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
	currentTab: number;
};

const initialState: InitialStateType = {
	currentTab: 0,
};

const prepare = (
	payload?: Record<string, any>,
	meta?: Record<string, any>,
	error?: Record<string, any>,
) => {
	return { payload, meta, error };
};

export const surveySlice = createSlice({
	name: 'survey',
	initialState,
	reducers: {
		SET_CURRENT_TAB: {
			reducer: (state, { payload }) => {
				state.currentTab = payload.currentTab;
			},
			prepare,
		},
	},
});

export const { SET_CURRENT_TAB } = surveySlice.actions;

export default surveySlice.reducer;
