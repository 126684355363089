import React from 'react';
import Image from 'next/image';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputLabel, Theme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import CalenderIcon from '@/assets/icons/calendar.svg';

const styles = {
	label: {
		fontSize: 13,
		fontWeight: 600,
		paddingBottom: 1,
		color: 'text.primary',
		lineHeight: '15.6px',
	},
	textField: {
		'& .MuiInputBase-root': {
			'& input': {
				fontSize: 13,
				padding: '11.5px 15px 11.5px 0px',
				'&::placeholder': {
					color: (theme: Theme) => theme.textColor.secondary,
					opacity: 1,
					fontSize: 13,
				},
			},
			'&:hover fieldset': {
				borderColor: (theme: Theme) => theme.palette.primary.main,
			},
			borderRadius: 2,
			'& fieldset': {
				borderColor: (theme: Theme) => theme.palette.text.primary,
			},
		},
		'& .MuiFormHelperText-root.Mui-error': {
			fontWeight: 600,
			ml: 0,
			textTransform: 'initial',
		},
	},
};

interface DatePickerProps {
	id?: string;
	name: string;
	label: string;
	error?: boolean;
	required?: boolean;
	placeholder?: string;
	value?: string | null;
	helperText?: string | boolean;
	onChange: (_arg: { target: { name: string; value: any } }) => void;
	onBlur?: (_event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

export const DatePicker = ({
	name,
	label,
	value = '',
	onChange,
	required,
	helperText,
	error = false,
	placeholder = '',
	...props
}: DatePickerProps) => {
	return (
		<>
			{label && (
				<InputLabel sx={styles.label}>{`${label} ${required ? '* ' : ' '}`}</InputLabel>
			)}
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<MuiDatePicker
					sx={styles.textField}
					slots={{
						openPickerIcon: (props) => <Image src={CalenderIcon} alt="icon" {...props} />,
					}}
					slotProps={{
						inputAdornment: { position: 'start', sx: { margin: 0 } },
						textField: { placeholder, helperText, error, name, fullWidth: true },
					}}
					value={value ? dayjs(value) : null}
					onChange={(newValue) =>
						onChange({
							target: {
								value: dayjs(newValue as Dayjs).isValid()
									? dayjs(newValue as Dayjs).format('MM/DD/YYYY')
									: '',
								name,
							},
						})
					}
					{...props}
				/>
			</LocalizationProvider>
		</>
	);
};
