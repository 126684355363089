import React, { ChangeEvent } from 'react';
import {
	Menu,
	Divider,
	Fade,
	Checkbox,
	checkboxClasses,
	InputAdornment,
	FormControlLabel,
	List,
	ListItem,
	Theme,
	Grid,
	CircularProgress,
	Typography,
} from '@mui/material';
import Image from 'next/image';
import SearchIcon from '@/assets/icons/Search.svg';
import { TextField } from './TextField';
import { Button } from './Button';
import { FilterOptionsType } from '@/utils/types';

type FilterPropsType = {
	chip: string;
	open: boolean;
	loading?: boolean;
	searchField: boolean;
	handleClose: () => void;
	selectedValues: string[];
	options: FilterOptionsType[];
	anchorEl: HTMLInputElement | null;
	handleReset: (chip: string) => void;
	handleSearch?: (_arg: string) => void;
	handleChange: (event: ChangeEvent<HTMLInputElement>, chip: string) => void;
};

const styles = {
	container: {
		minHeight: '600px',
		marginTop: '0.5rem',
		'& .MuiMenu-paper': {
			width: '320px',
			borderRadius: '8px',
		},
		'& .MuiMenu-list': {
			padding: 0,
		},
	},
	filterButton: {
		height: '46px',
		textAlign: 'right',
	},
	textField: {
		'& .MuiInputBase-root': {
			height: '46px',
			paddingX: '10px',
			input: {
				'&::placeholder': {
					color: (theme: Theme) => theme.palette.text.secondary,
					opacity: 1,
				},
				height: 'inherit',
				fontSize: '13px',
				lineHeight: '15.5px',
				padding: 0,
			},
			fieldset: {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
			'&.Mui-focused fieldset': {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
			'&:hover fieldset': {
				borderColor: (theme: Theme) => theme.borderColor.searchField,
			},
		},
	},
	divider: {
		color: (theme: Theme) => theme.borderColor.searchField,
	},
	list: {
		height: '220px',
		overflow: 'auto',
		padding: 0,
		paddingBottom: 2,
	},
	checkbox: {
		[`&, &.${checkboxClasses.checked}`]: {
			color: '#606E77',
		},
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '220px',
	},
};

export const Filter = ({
	chip,
	open,
	loading,
	options,
	anchorEl,
	handleReset,
	handleClose,
	searchField,
	handleChange,
	selectedValues,
	handleSearch = () => {},
}: FilterPropsType) => {
	return (
		<Menu
			sx={styles.container}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			TransitionComponent={Fade}
		>
			{searchField && (
				<TextField
					placeholder="Search..."
					variant="standard"
					type="search"
					customStyle={styles.textField}
					onChange={(event) => handleSearch(event.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Image src={SearchIcon} height={15} width={15} alt="search-icon" />
							</InputAdornment>
						),
						disableUnderline: true,
					}}
				/>
			)}

			<Divider sx={styles.divider} />
			<Grid
				container
				sx={styles.filterButton}
				alignItems="center"
				justifyContent="flex-end"
			>
				<Grid item xs={12}>
					<Button
						text="Reset Filter"
						variant="text"
						onClick={() => {
							handleReset(chip);
						}}
						customStyles={{ height: '16px' }}
					/>
				</Grid>
			</Grid>
			<Divider sx={styles.divider} />

			{loading ? (
				<Grid sx={styles.box}>
					<CircularProgress />
				</Grid>
			) : !loading && options?.length < 1 ? (
				<Grid sx={styles.box}>
					<Typography textAlign={'center'} alignContent={'center'} alignSelf={'center'}>
						{'No options found'}
					</Typography>
				</Grid>
			) : (
				<List sx={styles.list}>
					{options?.map((value) => (
						<ListItem
							key={value.id}
							sx={{ height: '53px', padding: '0px 16px 0px 16px' }}
						>
							<FormControlLabel
								control={
									<Checkbox
										id={value.id}
										sx={styles.checkbox}
										onChange={(event) => handleChange(event, chip)}
										checked={selectedValues?.includes(value.id) || false}
									/>
								}
								label={value.name || value.userName}
								sx={{
									'& .MuiTypography-root': {
										fontSize: '14px',
										lineHeight: '21px',
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										maxWidth: 260,
									},
								}}
							/>
						</ListItem>
					))}
				</List>
			)}
		</Menu>
	);
};
