import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import authSlice from './auth.slice';
import meSlice from './me.slice';
import filterChipsSlice from './filterChips.slice';
import layoutSlice from './layout.slice';
import surveySlice from './survey.slice';

const layoutPersistConfig = {
	key: 'layout',
	storage,
	whitelist: ['currentView'],
};

const rootReducer = combineReducers({
	auth: authSlice,
	me: meSlice,
	filterChips: filterChipsSlice,
	layout: persistReducer(layoutPersistConfig, layoutSlice),
	survey: surveySlice,
});
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
