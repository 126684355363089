import React, { FC, useState } from 'react';
import { Grid, IconButton, Theme, Tooltip, Typography, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Chip } from '@/components';
import { formatDate, isRecentNotification } from '@/utils/helpers';
import { NotificationType, OrganizationType } from '@/utils/types';

const styles = {
	container: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		p: 2,
		cursor: 'pointer',
	},
	notificationHeading: {
		fontSize: '13px',
		color: (theme: Theme) => theme.palette.text.primary,
		mb: 0.5,
		'& b': {
			display: 'inline-block',
			verticalAlign: 'top',
			maxWidth: '250px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	entityChip: {
		backgroundColor: '#F9F9FB',
		color: (theme: Theme) => theme.palette.text.secondary,
		height: '20px',
		cursor: 'pointer',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: 135,
	},
	circleIcon: {
		width: '8px',
		height: '8px',
		background: '#69A6B4',
		borderRadius: '100%',
	},
	checkIcon: {
		height: '16px',
		width: '16px',
	},
};

type NotificationMenuItemProps = {
	onClick: () => void;
	notification: NotificationType;
	markNotificationAsRead?: (notificationId: string) => void;
	markNotificationAsUnread?: (notificationId: string) => void;
};

export const NotificationMenuItem: FC<NotificationMenuItemProps> = ({
	onClick,
	notification,
	markNotificationAsRead,
	markNotificationAsUnread,
}) => {
	const [isCheckHovered, setIsCheckHovered] = useState(false);
	const { organization, task, dashboard } = notification;

	const entityName =
		organization?.name ||
		(task?.organization as OrganizationType)?.name ||
		dashboard?.organization?.name ||
		dashboard?.fund?.name;

	const toggleCheckbox = (event: any) => {
		event.stopPropagation();
		if (notification.isRead) {
			markNotificationAsUnread!(notification.id);
		} else {
			markNotificationAsRead!(notification.id);
		}
	};

	return (
		<Grid
			container
			alignItems="center"
			gap={1}
			mb={1}
			sx={styles.container}
			onClick={onClick}
			wrap="nowrap"
		>
			{!notification.isRead && isRecentNotification(notification) && (
				<Grid item textAlign="left">
					<Box sx={styles.circleIcon} />
				</Grid>
			)}
			<Grid item flexGrow={1} textAlign="left">
				<Typography variant="body1" sx={{ ...styles.notificationHeading }}>
					<div dangerouslySetInnerHTML={{ __html: notification.htmlContent }} />
				</Typography>
				<Box style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
					<Typography variant="body2" sx={{ fontSize: '12px', color: '#606E77' }}>
						{formatDate(notification.createdAt)}
					</Typography>
					{entityName && <Chip label={entityName} customStyle={styles.entityChip} />}
				</Box>
			</Grid>
			<Grid item textAlign="right">
				<Tooltip
					sx={{ textTransform: 'none' }}
					title={
						<Typography fontSize={11} sx={{ textTransform: 'none' }}>
							Mark as {notification.isRead ? 'unread' : 'read'}
						</Typography>
					}
				>
					<IconButton
						onMouseEnter={() => setIsCheckHovered(true)}
						onMouseLeave={() => setIsCheckHovered(false)}
						onClick={toggleCheckbox}
					>
						{(notification.isRead && !isCheckHovered) ||
						(!notification.isRead && isCheckHovered) ? (
							<CheckCircleOutlineIcon sx={styles.checkIcon} />
						) : (
							<RadioButtonUncheckedIcon sx={styles.checkIcon} />
						)}
					</IconButton>
				</Tooltip>
			</Grid>
		</Grid>
	);
};
