import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { isEmpty } from 'lodash';
import { AppProps } from 'next/app';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { Loader, PrivateRoute } from '@/components';
import useHubspotChat from '@/hooks/useHubspotChat';
import { useLogout } from '@/hooks/useLogout';
import useMixpanel from '@/hooks/useMixpanel';
import { BaseLayout } from '@/layout';
import { GET_AUTH_ME, LOGOUT } from '@/store/actions';
import { useAuthService } from './store/services/auth.service';
import { RootState } from '@/store/slices';
import { APP_ROUTES, SPLIT_CLIENT_KEY } from '@/utils/constants';
import { canAccessRoute, isSuperAdmin } from '@/utils/helpers';

function AppContent({ Component, pageProps, router }: AppProps) {
	const { logout } = useLogout();
	const dispatch = useDispatch();
	const { initializeMixpanel } = useMixpanel();
	const [routes, setRoutes] = useState<string[]>([]);
	const { token, impersonatedUserId } = useSelector((state: RootState) => state.auth);
	const { data: user, meLoading } = useSelector((state: RootState) => state.me);
	const { getLoggedInUserDetails } = useAuthService();
	const { openHandler } = useHubspotChat();

	useEffect(() => {
		initializeMixpanel();
		if (user) {
			openHandler();
		}
	}, [user, openHandler, initializeMixpanel]);

	useEffect(() => {
		if (token) {
			const { exp } = jwt.decode(token) as JwtPayload;
			if (exp) {
				if (exp * 1000 < Date.now()) {
					logout({
						async openUrl() {
							window.location.replace('/login');
						},
					});
					dispatch(LOGOUT());
				}
			}
		}
	}, [token, dispatch, logout]);

	const getAuthMe = useCallback(() => {
		dispatch(GET_AUTH_ME({ getLoggedInUserDetails, impersonatedUserId }));
	}, [dispatch, getLoggedInUserDetails, impersonatedUserId]);

	useEffect(() => {
		if (token && isEmpty(user)) {
			getAuthMe();
		}
	}, [getAuthMe, token, user]);

	useEffect(() => {
		if (!isEmpty(user)) {
			if (isSuperAdmin(user)) {
				const allRoutes = APP_ROUTES.map((route) => route.path);
				setRoutes([...allRoutes]);
			} else {
				const routes = APP_ROUTES.filter((route) => canAccessRoute(user, route)).map(
					(route) => route.path,
				);
				setRoutes([...routes]);
			}
		}
	}, [user]);

	const splitConfig = {
		core: {
			authorizationKey: SPLIT_CLIENT_KEY,
			key: user?.id || 'anonymous',
		},
	};

	let LayoutComponent = null;
	if (routes.length) {
		const showHeaderAndSideMenu = routes.includes(router.pathname);
		if (showHeaderAndSideMenu && !isEmpty(user)) {
			LayoutComponent = BaseLayout;
		} else if (
			!showHeaderAndSideMenu &&
			router.pathname !== '/login' &&
			router.pathname !== '/secure-survey/[id]' &&
			router.pathname !== '/secure-file-drop' &&
			router.pathname !== '/_error' &&
			!isEmpty(user)
		) {
			LayoutComponent = PrivateRoute;
		} else {
			LayoutComponent = Fragment;
		}
	} else {
		LayoutComponent = Fragment;
	}

	if ((meLoading || isEmpty(user)) && token) {
		return <Loader />;
	}

	return (
		<SplitFactoryProvider config={splitConfig}>
			<LayoutComponent>
				<Component {...pageProps} />
			</LayoutComponent>
		</SplitFactoryProvider>
	);
}

export default AppContent;
