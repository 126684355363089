import { FC, ReactNode } from 'react';
import {
	Accordion as MuiAccordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Theme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = {
	root: {
		width: '100%',
		position: 'unset',
		'&.Mui-expanded': {
			borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
		},
	},
	accordionSummary: {
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
		minHeight: '52px',
	},
};

interface Props {
	children: ReactNode;
	title: string;
}

export const Accordion: FC<Props> = ({ children, title = '' }) => {
	return (
		<MuiAccordion square disableGutters sx={styles.root} elevation={0}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={styles.accordionSummary}>
				<Typography fontSize="14px" fontWeight={600}>
					{title}
				</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{ p: '16px' }}>{children}</AccordionDetails>
		</MuiAccordion>
	);
};
