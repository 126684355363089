import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Grid, Box, Typography, Theme } from '@mui/material';
import { Button } from '@/components';
import NotAllowed from '../assets/icons/NotAllowed.svg';
import TableclothLogo from '../assets/icons/TableclothLogo.svg';

const styles = {
	main: {
		paddingTop: '5em',
		height: '100vh',
		bgcolor: (theme: Theme) => theme.palette.common.white,
	},
	heading: {
		color: (theme: Theme) => theme.textColor.primary,
		fontSize: '28px',
		fontWeight: 600,
		fontFamily: 'Poppins',
	},
	text: {
		fontSize: '20px',
		color: 'text.primary',
	},
};

export const PrivateRoute = () => {
	const router = useRouter();
	return (
		<Box sx={styles.main}>
			<Grid container direction="column" alignContent="center" gap={2}>
				<Grid item>
					<Grid item container justifyContent="center">
						<Image priority src={TableclothLogo} alt="logo" width={100} />
					</Grid>
				</Grid>
				<Grid item>
					<Grid item container justifyContent="center">
						<Image src={NotAllowed} width={250} alt="image" />
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction="column" gap={2}>
						<Grid item container direction="column">
							<Typography sx={styles.heading}>Oops...</Typography>
							<Typography sx={styles.text}>
								You don&apos;t have permissions to view this page.
							</Typography>
						</Grid>
						<Grid item container direction="column">
							<Button
								onClick={() => {
									router.back();
								}}
								text="Go back"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};
