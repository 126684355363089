import { createSlice } from '@reduxjs/toolkit';
import { UserType } from '@/utils/types';

type initialStateType = {
	meLoading: boolean;
	data: UserType | null;
	error: Record<string, any> | null;
};
const initialState: initialStateType = {
	meLoading: false,
	data: null,
	error: null,
};

const prepare = (
	payload?: Record<string, any>,
	meta?: Record<string, any>,
	error?: Record<string, any>,
) => {
	return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};
export const meSlice = createSlice({
	name: 'me',
	initialState,
	reducers: {
		GET_AUTH_ME: {
			reducer: () => {},
			prepare,
		},
		GET_AUTH_ME_PENDING: {
			reducer: (state, { payload }) => {
				state.meLoading = payload.loading;
			},
			prepare,
		},
		GET_AUTH_ME_SUCCESS: {
			reducer: (state, { payload }) => {
				state.data = payload.result;
				state.meLoading = false;
			},
			prepare,
		},
		GET_AUTH_ME_ERROR: {
			reducer: (state, { payload }) => {
				state.error = payload.err;
				state.meLoading = false;
			},
			prepare,
		},
	},
});

export const {
	GET_AUTH_ME,
	GET_AUTH_ME_SUCCESS,
	GET_AUTH_ME_PENDING,
	GET_AUTH_ME_ERROR,
} = meSlice.actions;

export default meSlice.reducer;
