import { FC, ReactElement } from 'react';
import { Grid, Typography, Theme, GridDirection, Avatar, Tooltip } from '@mui/material';
import { Chip, AvatarGroup, ChipGroup } from '@/components';
import { InfoItemType } from '@/utils/types';
import { getChipColor, getWorkflow, snakeCaseToLabel } from '@/utils/helpers';

interface InfoContainerProps {
	infoItems: InfoItemType[];
	taskType?: string;
}

const styles = {
	avatar: { height: '28px', width: '28px' },
};

const renderItem = (
	{ label, value, type, tooltipText }: InfoItemType,
	taskType: string,
): ReactElement => {
	switch (type) {
		case 'chip':
			return (
				<>
					{Array.isArray(value) ? (
						<>
							<ChipGroup
								chips={value as string[]}
								customStyle={{
									background: (theme: Theme) => theme.palette.primary.main,
								}}
							/>
						</>
					) : (
						<Chip
							label={
								label === 'Workflow'
									? getWorkflow(value as string, taskType)!.name
									: snakeCaseToLabel(value as string)
							}
							customStyle={{
								...(label === 'Workflow' && {
									height: 'auto',
									'& .MuiChip-label': {
										width: getWorkflow(value as string, taskType)!.width,
										fontSize: '11px',
										whiteSpace: 'normal',
										textTransform: 'none',
										WebkitLineClamp: '3',
									},
								}),
								...(label === 'Priority' && {
									'& .MuiChip-label': {
										padding: '8px',
									},
								}),
								background: getChipColor(value as string),
							}}
						/>
					)}
				</>
			);
		case 'avatar':
			return (
				<>
					{Array.isArray(value) ? (
						<AvatarGroup data={value as Record<string, any>[]} />
					) : (
						<Tooltip title={(value as { userName: string })?.userName}>
							<Avatar
								src={(value as { pictureUrl: string })?.pictureUrl}
								sx={styles.avatar}
							/>
						</Tooltip>
					)}
				</>
			);
		default:
			return (
				<Tooltip title={tooltipText} placement="bottom-start">
					<Typography fontSize="14px" color="textPrimary">
						{value as string}
					</Typography>
				</Tooltip>
			);
	}
};

export const InfoContainer: FC<InfoContainerProps> = ({ infoItems, taskType }) => {
	return (
		<Grid item container gap={2}>
			{!!infoItems.length &&
				infoItems.map(({ value, label, type, direction, tooltipText }) => (
					<Grid
						container
						direction={direction as GridDirection}
						key={label}
						alignItems={direction === 'column' ? 'start' : 'center'}
						py="5px"
						wrap="nowrap"
					>
						<Grid item xs={6}>
							<Typography
								fontSize="13px"
								fontWeight={600}
								lineHeight="21px"
								color="textPrimary"
								whiteSpace="pre-line"
							>
								{label}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							{value &&
								renderItem({ label, value, type, tooltipText }, taskType as string)}
						</Grid>
					</Grid>
				))}
		</Grid>
	);
};
