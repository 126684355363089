import React from 'react';
import { Theme, Typography } from '@mui/material';
import { Modal } from './Modal';

type DeleteModalPropsType = {
	children?: React.ReactNode;
	actionBtnTitle: string;
	open: boolean;
	handleClose: () => void;
	title: string;
	handleDelete?: () => void;
	loading?: boolean;
	entityName?: string;
};

const styles = {
	deleteBtn: {
		background: '#A94943',
		color: (theme: Theme) => theme.palette.common.white,
		'&:hover': { background: '#A94943' },
	},
};

export const DeleteModal = (props: DeleteModalPropsType) => {
	const {
		loading,
		handleDelete,
		handleClose,
		open,
		title,
		actionBtnTitle,
		entityName,
		children,
	} = props;
	return (
		<Modal
			open={open}
			title={title}
			loading={loading}
			handleClose={handleClose}
			handleSubmit={handleDelete}
			actionBtnTitle={actionBtnTitle}
			saveButtonStyle={styles.deleteBtn}
		>
			{children ? (
				children
			) : (
				<Typography
					fontSize="14px"
					lineHeight="21px"
					color={(theme: Theme) => theme.palette.text.primary}
				>
					Are you sure you want to delete {entityName}? This action cannot be undone.
				</Typography>
			)}
		</Modal>
	);
};
