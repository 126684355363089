import { useAxios } from '@/hooks/useAxios';

export const useUserOrganizationGroupService = () => {
	const { patch, isLoading } = useAxios();

	const updateDefaultAccount = (
		data: { organizationId: string; groupId: string },
		message: string | null,
	) => patch('/user-organization-groups/update-default-account', data, message);

	return { updateDefaultAccount, isLoadingUserOrganizationGroup: isLoading };
};
