import React from 'react';
import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

type SearchInputPropsType = {
	placeholder?: string;
	fieldVariant?: 'standard' | 'filled' | 'outlined' | undefined;
	onChange?: () => void;
};

const styles = {
	container: {
		borderRadius: '4px',
		outline: 'none',
	},
};

export const SearchInput = ({
	placeholder = 'Search Tasks',
	onChange = () => {},
	fieldVariant = 'outlined',
}: SearchInputPropsType) => {
	return (
		<TextField
			fullWidth
			placeholder={placeholder}
			size="small"
			variant={fieldVariant}
			onChange={onChange}
			sx={styles.container}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Search />
					</InputAdornment>
				),
			}}
		/>
	);
};
