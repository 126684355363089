import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Dialog, DialogContent, Grid, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { RootState } from '@/store/slices';
import { Button } from './Button';
import { GET_AUTH_ME } from '@/store/actions';
import { useAuthService } from '@/store/services/auth.service';
import { useUserService } from '@/store/services/user.service';
import { USER_STATUS } from '@/utils/enums';

export const PasswordChangeAlert = () => {
	const router = useRouter();
	const dispatch = useDispatch();
	const { data } = useSelector((state: RootState) => state.me);
	const { impersonatedUserId } = useSelector((state: RootState) => state.auth);
	const { updateUser } = useUserService();
	const { getLoggedInUserDetails } = useAuthService();

	const handleClosePasswordChangeAlert = async () => {
		const result = data && (await updateUser(data.id, { status: USER_STATUS.ACCEPTED }));
		if (result) {
			dispatch(GET_AUTH_ME({ getLoggedInUserDetails, impersonatedUserId }));
		}
	};

	const handleChangePassword = async () => {
		handleClosePasswordChangeAlert();
		router.push('/profile');
	};

	return (
		<Dialog open={data?.status === USER_STATUS.INVITED} maxWidth="xs" fullWidth>
			<Grid container justifyContent="space-between" alignItems="center" px={1}>
				<Typography fontWeight={600} fontSize="18px">
					Alert
				</Typography>
				<IconButton aria-label="close" onClick={handleClosePasswordChangeAlert}>
					<CloseIcon />
				</IconButton>
			</Grid>
			<DialogContent sx={{ pb: 3 }} dividers>
				<Grid container justifyContent="center" pb={1}>
					<ReportGmailerrorredIcon
						sx={{ width: '40px', height: '40px' }}
						color="warning"
					/>
				</Grid>
				<Typography textAlign="justify" align="center" fontSize="14px">
					You are currently using a default password for your account. We strongly
					recommend changing your password for security reasons. To do so, click the
					button below or visit your profile page, which can be accessed via the upper
					right menu.
				</Typography>
				<Grid container item justifyContent="center">
					<Button text="Set a new password" onClick={handleChangePassword} />
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
