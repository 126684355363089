import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
	switchingToWorkspace: string | null;
	currentView: string;
};

const initialState: InitialStateType = {
	switchingToWorkspace: null,
	currentView: 'all',
};

const prepare = (
	payload?: Record<string, any>,
	meta?: Record<string, any>,
	error?: Record<string, any>,
) => {
	return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};

export const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		SWITCH_WORKSPACE: {
			reducer: (state, { payload }) => {
				state.switchingToWorkspace = payload.switchingToWorkspace;
			},
			prepare,
		},
		SET_CURRENT_VIEW: {
			reducer: (state, { payload }) => {
				state.currentView = payload.currentView;
			},
			prepare,
		},
	},
});

export const { SWITCH_WORKSPACE, SET_CURRENT_VIEW } = layoutSlice.actions;

export default layoutSlice.reducer;
