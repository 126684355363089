import { Menu as MuiMenu, MenuItem, Grid, Theme } from '@mui/material';
import Image from 'next/image';
import { MenuItemType } from '@/utils/types';

const styles = {
	paper: {
		mt: 1,
		borderRadius: '8px',
		'& ul': {
			p: 0,
		},
	},
	menuItem: {
		fontSize: '13px',
		p: '16px',
		height: '53px',
		minWidth: '200px',
		borderColor: (theme: Theme) => theme.borderColor.toolbar,
	},
};

type MenuPropType = {
	anchorEl: HTMLElement | null;
	items: MenuItemType[];
	handleClose: () => void;
	anchorOrigin?: {
		vertical: 'top' | 'center' | 'bottom' | number;
		horizontal: 'left' | 'center' | 'right' | number;
	};
	transformOrigin?: {
		vertical: 'top' | 'center' | 'bottom' | number;
		horizontal: 'left' | 'center' | 'right' | number;
	};
};
export const Menu = ({
	anchorEl,
	items,
	handleClose,
	anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
}: MenuPropType) => {
	return (
		<MuiMenu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
			PaperProps={{ sx: styles.paper }}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
		>
			{items.map((item: any, index: number) => (
				<MenuItem
					key={item.name}
					onClick={() => {
						handleClose();
						item?.onClick && item.onClick();
					}}
					divider={index !== items.length - 1}
					sx={styles.menuItem}
				>
					{item?.component ? (
						<item.component />
					) : (
						<Grid container gap={1}>
							{item?.icon && (
								<Grid item>
									<Image src={item?.icon} alt={item.name} />
								</Grid>
							)}
							<Grid item>{item.name}</Grid>
						</Grid>
					)}
				</MenuItem>
			))}
		</MuiMenu>
	);
};
