import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';
import { CPV1_API_URL } from '@/utils/constants';
import { LookerCpv1PayloadType, LookerTemplateFiltersType } from '@/utils/types';

export const useLookerTemplateService = () => {
	const { get, post, patch, remove, isLoading } = useAxios();
	const { post: cpv1Post } = useAxios(CPV1_API_URL);

	const getLookerTemplates = useCallback(
		(queryFilters: LookerTemplateFiltersType) => get('/looker-templates', queryFilters),
		[get],
	);

	const createLookerTemplate = useCallback(
		(data: Record<string, any>) => post('/looker-templates', data),
		[post],
	);

	const updateLookerTemplate = useCallback(
		(id: string, data: Record<string, any>, message?: string | null) =>
			patch(`/looker-templates/${id}`, data, message),
		[patch],
	);

	const deleteLookerTemplate = useCallback(
		(id: string) => remove(`/looker-templates/${id}`),
		[remove],
	);

	const getLookerDashboardLink = useCallback(
		(payload: LookerCpv1PayloadType) =>
			cpv1Post('/looker_dashboards/get-cpv2-link', payload, null),
		[cpv1Post],
	);

	return {
		getLookerTemplates,
		createLookerTemplate,
		updateLookerTemplate,
		deleteLookerTemplate,
		getLookerDashboardLink,
		isLoadingLookerTemplates: isLoading,
	};
};
