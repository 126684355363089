import React, { FC, ReactNode, useState } from 'react';
import { Box, Typography, Theme, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Icon {
	data: ReactNode;
}

interface Detail {
	heading: string;
	children: ReactNode;
	rightSideIcon?: ReactNode;
	leftSideIcon?: ReactNode;
}

interface SidebarProps {
	expandWidth?: string;
	showSidebar: boolean;
	setShowSidebar: (_showSidebar: boolean) => void;
	icons: Icon[];
	details: Detail[];
}

type MakeStyle = {
	showSidebar: boolean;
};
const makeStyles = ({ showSidebar }: MakeStyle) => ({
	container: {
		width: '100%',
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		height: showSidebar ? 'calc(100vh - 191px)' : 'calc(100vh - 186px)',
		transition: 'ease-in-out 300ms',
	},
	rightSideContent: {
		background: (theme: Theme) => theme.palette.common.white,
		transition: 'ease-in-out 300ms',
		width: showSidebar ? '384px' : '63px',
		borderLeft: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
		borderRight: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
	},
	header: {
		padding: '16px 24px',
		alignItems: 'center',
		background: (theme: Theme) => theme.palette.common.white,
		justifyContent: 'space-between',
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
	},
});

export const Sidebar: FC<SidebarProps> = ({
	showSidebar,
	setShowSidebar,
	icons,
	details,
}) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(1);
	const [activeItem, setActiveItem] = useState<number | null>(1);

	const styles = makeStyles({
		showSidebar,
	});

	const handleClick = (index: number) => {
		setSelectedIndex(index);
		setShowSidebar(true);
		setActiveItem(index);
	};

	const handleClose = () => {
		setShowSidebar(false);
	};

	return (
		<Grid container sx={styles.rightSideContent} wrap="nowrap">
			<Grid item>
				<Grid container flexDirection="column" p="16px 8px 8px" gap="12px">
					{icons &&
						icons.map((item, index) => (
							<Grid
								key={index}
								sx={{
									bgcolor:
										activeItem === index && showSidebar === true
											? (theme: Theme) => theme.borderColor.toolbar
											: 'transparent',
									p: '10px',
									borderRadius: '8px',
								}}
								onClick={() => handleClick(index)}
							>
								<Box sx={{ cursor: 'pointer', height: '22.4px', width: '22.4px' }}>
									{item.data}
								</Box>
							</Grid>
						))}
				</Grid>
			</Grid>

			{showSidebar && (
				<Grid
					item
					sx={{ borderLeft: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}` }}
				>
					{details[selectedIndex] && (
						<Box key={2}>
							<Grid container sx={styles.header}>
								<Grid item>
									<Grid container gap="10px" alignItems="start">
										<Typography
											fontSize="14px"
											fontWeight="600"
											lineHeight="17px"
											color={(theme: Theme) => theme.textColor.primary}
										>
											{details[selectedIndex].heading}
										</Typography>
										<Box>{details[selectedIndex].leftSideIcon}</Box>
									</Grid>
								</Grid>
								<Grid item sx={{ cursor: 'pointer' }}>
									<Grid container gap="10px" alignItems="start">
										<Box>{details[selectedIndex].rightSideIcon}</Box>
										<CloseIcon
											onClick={handleClose}
											sx={{ width: 20, height: 20, color: 'text.secondary' }}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Box sx={{ ...styles.container }}>{details[selectedIndex].children}</Box>
						</Box>
					)}
				</Grid>
			)}
		</Grid>
	);
};
