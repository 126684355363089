import React from 'react';
import {
	TextField as MuiTextField,
	InputLabel,
	CircularProgress,
	Theme,
	InputProps,
} from '@mui/material';

type TextFieldPropsType = {
	label?: string;
	type?: 'text' | 'date' | 'password' | 'number' | 'search';
	loading?: boolean;
	size?: 'small' | 'medium';
	placeholder?: string;
	InputProps?: InputProps;
	customStyle?: Record<string, any>;
	variant?: 'outlined' | 'filled' | 'standard';
	name?: string;
	onChange?: (_event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (_event: React.FocusEvent<HTMLInputElement, Element>) => void;
	onFocus?: (_event: React.FocusEvent<HTMLInputElement, Element>) => void;
	value?: string;
	rows?: number;
	multiline?: boolean;
	inputRef?: React.RefObject<HTMLInputElement | null>;
	required?: boolean;
	error?: boolean;
	helperText?: string | boolean;
	id?: string;
	propagation?: boolean;
	disabled?: boolean;
};

const styles = {
	label: {
		fontSize: 13,
		fontWeight: 600,
		paddingBottom: 1,
		color: 'text.primary',
		lineHeight: '15.6px',
	},
	textField: {
		'& .MuiInputBase-root': {
			padding: '11.5px 15px',
			'& input': {
				padding: 0,
				fontSize: 13,
				'&::placeholder': {
					color: (theme: Theme) => theme.palette.text.secondary,
					opacity: 1,
					fontSize: 13,
				},
			},
			'& textarea': {
				'&::placeholder': {
					color: (theme: Theme) => theme.palette.text.secondary,
					opacity: 1,
					fontSize: 13,
				},
			},
			'&:hover fieldset': {
				borderColor: (theme: Theme) => theme.palette.primary.main,
			},
			borderRadius: 2,
			'& fieldset': {
				borderColor: (theme: Theme) => theme.palette.text.primary,
			},
		},
		'& .MuiFormHelperText-root.Mui-error': {
			fontWeight: 600,
			ml: 0,
			textTransform: 'initial',
		},
	},
};

export const TextField = ({
	label = '',
	type = 'text',
	loading = false,
	size = 'medium',
	placeholder = '',
	customStyle = {},
	variant = 'outlined',
	required = false,
	propagation = false,
	disabled = false,
	...props
}: TextFieldPropsType) => {
	return (
		<>
			{label && (
				<InputLabel sx={styles.label}>
					{`${label} ${required ? '* ' : ' '}`}
					{loading && (
						<CircularProgress
							sx={{ width: '14px !important', height: '14px !important' }}
						/>
					)}
				</InputLabel>
			)}
			<MuiTextField
				variant={variant}
				fullWidth
				sx={{ ...styles.textField, ...customStyle }}
				type={type}
				size={size}
				placeholder={placeholder}
				{...props}
				onKeyDown={
					propagation
						? (e) => {
								e.stopPropagation();
						  }
						: undefined
				}
				disabled={disabled}
			/>
		</>
	);
};
