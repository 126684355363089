import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/slices';
import { HUBSPOT_PORTAL_ID } from '@/utils/constants';
import { useRouter } from 'next/router';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

const useHubspotChat = () => {
	const router = useRouter();
	const { setPathPageView, setIdentity } = useTrackingCode();
	const { data: user } = useSelector((state: RootState) => state.me);

	const eventRef = useRef<((payload: any) => void) | null>(null);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [activeConversation, setActiveConversation] = useState<boolean>(false);
	const scriptRef = useRef<HTMLScriptElement | null>(null); // Use useRef for script

	useEffect(() => {
		const onReady = () => {
			setHasLoaded(true);
		};

		setIdentity(user?.email || '');
		setPathPageView(router.pathname);
		(window as any).hsConversationsOnReady = [onReady];
		scriptRef.current = document.createElement('script');
		scriptRef.current.src = `//js.hs-scripts.com/${HUBSPOT_PORTAL_ID}.js`;
		scriptRef.current.async = true;

		document.body.appendChild(scriptRef.current);

		return () => {
			if (scriptRef.current) {
				document.body.removeChild(scriptRef.current);
			}
			(window as any).hsConversationsOnReady = undefined;
		};
	}, [user, setIdentity, setPathPageView, router]);

	useEffect(() => {
		eventRef.current = (payload: any) => {
			setActiveConversation(payload.conversation.conversationId);
		};

		if (hasLoaded && (window as any).HubSpotConversations) {
			(window as any).HubSpotConversations.on('conversationStarted', eventRef.current);
		}

		return () => {
			if (hasLoaded && (window as any).HubSpotConversations) {
				(window as any).HubSpotConversations.off('conversationStarted', eventRef.current);
			}
		};
	}, [hasLoaded]);

	const openHandler = useCallback(() => {
		if (hasLoaded && (window as any).HubSpotConversations) {
			(window as any).HubSpotConversations.widget.load();
		}
	}, [hasLoaded]);

	const closeHandler = useCallback(() => {
		if (hasLoaded && (window as any).HubSpotConversations) {
			(window as any).HubSpotConversations.widget.remove();
		}
	}, [hasLoaded]);

	return {
		hasLoaded,
		activeConversation,
		openHandler,
		closeHandler,
	};
};

export default useHubspotChat;
