import Image from 'next/image';
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Button } from './Button';
import Tree from '@/assets/icons/Tree.svg';
import Border from '@/assets/icons/Border.svg';
import Building from '@/assets/icons/Building.svg';

type NoDataPropsType = {
	primaryText: string;
	primaryTextSize?: string;
	secondaryText?: string;
	btnText?: string;
	btnHandler?: () => void;
	animationComponent?: React.ReactNode;
	canEdit?: boolean;
	backgroundColor?: string;
};

const styles = {
	container: {
		padding: { xs: '16px', sm: '0' },
	},
	animationContainer: {
		overflow: 'hidden',
	},
	buildingAnimation: {
		animation: 'buildingmove 6s infinite',
		position: 'relative',
		width: '120px',
		height: '64px',
	},
	animatedItems: {
		width: '120px',
		position: 'absolute',
	},
};

export const NoData = ({
	primaryText = '',
	primaryTextSize,
	secondaryText = '',
	btnText = '',
	btnHandler,
	animationComponent,
	canEdit,
	backgroundColor,
}: NoDataPropsType) => {
	return (
		<Grid
			container
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			minHeight="300px"
			gap={1}
			sx={{ ...styles.container, backgroundColor: backgroundColor || 'white' }}
		>
			<Grid item>
				{animationComponent ? (
					animationComponent
				) : (
					<Box sx={styles.animationContainer}>
						<Box sx={styles.buildingAnimation}>
							<Box sx={styles.animatedItems} left="0px">
								<Image src={Border} alt="border" height={60} />
							</Box>
							<Box sx={styles.animatedItems} left="120px">
								<Image src={Building} alt="building" height={60} />
							</Box>
							<Box sx={styles.animatedItems} left="240px">
								<Image src={Tree} alt="tree" height={60} />
							</Box>
							<Box sx={styles.animatedItems} left="360px">
								<Image src={Border} alt="border" height={60} />
							</Box>
						</Box>
					</Box>
				)}
			</Grid>
			<Grid item>
				<Typography
					fontSize={primaryTextSize || '18px'}
					color="#283252"
					textAlign="center"
				>
					{primaryText}
				</Typography>
			</Grid>
			{canEdit && (
				<>
					<Grid item fontSize="14px" color="#283252" textAlign="center">
						<Typography>{secondaryText}</Typography>
					</Grid>
					<Grid item sx={{ width: { xs: '100%', sm: 'unset' } }}>
						{btnText && (
							<Button
								variant="contained"
								onClick={btnHandler}
								text={btnText}
								customStyles={{ height: '36px', borderRadius: '4px' }}
								fullWidth
							/>
						)}
					</Grid>
				</>
			)}
		</Grid>
	);
};
