import { ResponseGeneratorType } from '@/utils/types';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
	GET_AUTH_ME,
	GET_AUTH_ME_ERROR,
	GET_AUTH_ME_PENDING,
	GET_AUTH_ME_SUCCESS,
	LOG_IN_AS_NON_TC_USER,
} from '../actions';

function* getLoggedInUserDetails({ payload }: { payload: Record<string, any> }) {
	try {
		const { impersonatedUserId } = payload;
		yield put(GET_AUTH_ME_PENDING({ loading: true }));
		const result: ResponseGeneratorType = yield call(
			payload.getLoggedInUserDetails,
			impersonatedUserId,
		);
		if (impersonatedUserId) yield put(LOG_IN_AS_NON_TC_USER({ impersonatedUserId }));
		yield put(GET_AUTH_ME_SUCCESS({ result }));
	} catch (err) {
		yield put(GET_AUTH_ME_ERROR({ err }));
	}
}

export default function* authSaga() {
	yield all([takeEvery(GET_AUTH_ME, getLoggedInUserDetails)]);
}
