import React, { FC } from 'react';
import dayjs from 'dayjs';
import Link from 'next/link';
import { Typography, Theme, Grid, Tooltip } from '@mui/material';
import TourIcon from '@mui/icons-material/Tour';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Chip } from '@/components';
import { TASK_STATUS, WORKFLOW_STATUS } from '@/utils/enums';
import { getChipColor, getWorkflow } from '@/utils/helpers';
import { OrganizationType, TaskType } from '@/utils/types';

type TaskCardProps = {
	provided?: DraggableProvided;
	task: TaskType;
};

const styles = {
	container: {
		padding: '20px',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		cursor: 'pointer',
	},
	heading: {
		fontSize: '18px',
		lineHeight: '22px',
		color: (theme: Theme) => theme.textColor.dark,
	},
	icon: {
		color: '#E69D59',
	},
	chip: {
		backgroundColor: '#F9F9FB',
		color: (theme: Theme) => theme.palette.text.secondary,
		height: '29px',
		'& .MuiChip-label': {
			padding: '8px 10px',
		},
	},
};

const getBackgroundColor = (task: TaskType) =>
	task.status !== TASK_STATUS.DONE && dayjs().isAfter(task.dueDate, 'day')
		? '#fff0f0'
		: task.workflowStatus === WORKFLOW_STATUS.IN_REVIEW
		  ? '#F9F9FB'
		  : (theme: Theme) => theme.palette.common.white;

export const TaskCard: FC<TaskCardProps> = ({ provided, task }) => {
	const organization = task.organization as OrganizationType;
	const card = (
		<Link href={`/tasks/details/TC-${task.friendlyId}`}>
			<Grid
				container
				direction="column"
				sx={{
					...styles.container,
					background: getBackgroundColor(task),
				}}
			>
				<Grid container item mb={2} spacing={1.5}>
					<Grid item flexGrow={1} flex={9}>
						<Typography sx={styles.heading}>{task.name}</Typography>
					</Grid>
					<Grid item flex={1}>
						<Chip label={organization.name} customStyle={styles.chip} />
					</Grid>
				</Grid>
				<Grid container item alignItems="center" justifyContent="space-between">
					<Grid item>
						<Grid container alignItems="center" gap={1}>
							<Grid item>
								<Grid container alignItems="center" gap={1}>
									<Tooltip title={`${task.priority} Priority`}>
										<TourIcon sx={{ fill: getChipColor(task.priority) }} />
									</Tooltip>
								</Grid>
							</Grid>
							<Chip
								label={`Due ${dayjs(task.dueDate).format('MM/DD/YYYY')}`}
								customStyle={styles.chip}
							/>
						</Grid>
					</Grid>
					{task.workflowStatus && (
						<Chip
							label={getWorkflow(task.workflowStatus, task.taskType)?.name}
							size="small"
							customStyle={{
								background: getChipColor(task.workflowStatus),
								'& .MuiChip-label': {
									textTransform: 'none',
								},
							}}
						/>
					)}
				</Grid>
			</Grid>
		</Link>
	);
	return provided ? (
		<Grid
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
		>
			{card}
		</Grid>
	) : (
		card
	);
};
