import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';

export const useLogout = () => {
	const { logout } = useAuth0();

	return {
		logout: (props: LogoutOptions | undefined) => {
			logout(props);
		},
	};
};
